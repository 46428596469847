/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import dayjs from 'dayjs';
import _ from 'lodash';
import { createContext, useContext, useRef, useState } from 'react';
import { Helmet } from "react-helmet";

import { Button, Container, Form } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import { useParams } from "react-router"
import { Link, Redirect } from "react-router-dom";
import { PurpleBanner } from './components/banners';
import { FC_DARK_BLUE, FC_BLUE, FC_LIGHTER_BLUE, FC_LIGHT_BLUE, NEUTRAL_20, NEUTRAL_40, NEUTRAL_50 } from './emotionVariables';
import { useFollowHost, useGetHostProfile, useUnfollowHost, useUpdateHost, useGetClub } from './fetch/endpoints';
import { useAutomaticallyFetch } from './fetch/helpers';
import { baseURL } from './firebase';
import { UserContext, useGetDisplayName } from './UserProvider';
import { copyToClipboard, useAuthToken } from './utils';
import { MainLoading } from './commonLayout/MainLoading';
import { SadOctoPage } from './commonLayout/SadOctoPage';
import { BUTTON_STYLES, DarkText, FlowButton, LightGrayText, LoadingIndicator, Modal, Text, TextArea, TextInput, TEXT_INPUT_TYPES, TEXT_STYLES, LinkStyledText } from './components/flowComponents';
import { getUserCanHostSessions, sortBy, truncateTextWithEllipses } from '../functions/shared/helpers';
import { EventCard } from './Sessions/EventCard';
import Icon, { TYPE_LINKEDIN_CIRCLE, TYPE_TWITTER_CIRCLE, TYPE_LINK, TYPE_STAR } from './daily/daily-components/Icon/Icon';
import { SegmentProvider, useSendSegmentEvent } from './wrappers/SegmentProvider';
import { useModal } from './wrappers/MagnificentlyMunificentModalManager';
import { toastError, toastSuccess } from './components/utils/toaster';
import { TooltipWrapper } from './components/TooltipWrapper';
import { EditIcon } from './event-utils';
import { uploadPhoto } from './fetch/uploadPhoto';
import { getCroppedImageFile, CircularImageCropModal, showCropModalForFileSelected } from './HostOnboarding';
import { UpdateNameModal } from './Sessions/modals/UpdateNameModal';
import { MUSIC_STYLES } from '../functions/shared/constants';
import { FloatingCreateSessionButton } from './Sessions/Schedule/CreateSessionButton';

const mobileViewMediaQuery = {query: '(max-width: 1199px)'}
const reallySuperDuperMobileViewMediaQuery = {query: '(max-width: 600px)'}

export const HostProfileInfoContext = createContext(null);

// real insightful naming here
export const WhiteBackgroundSection = ({ children, customCss }) => {
  const mobileView = useMediaQuery(mobileViewMediaQuery)
  return (<div css={css`
    background-color: white;
    ${!mobileView && 'border-radius: 8px;'}

    ${customCss}`}
  >
    {children}
  </div>)
}

const getHostImageData = hostUser => _.pick(hostUser, ['image64', 'image100', 'image'])

const getHostImageUrl = (size, { image64, image100, image }) => {
  if (size > 100 && image) { return image }
  if (size > 64 && image100) { return image100 }
  return image64
}

export const HostProfileRedirect = () => {
  const { user } = useContext(UserContext)
  const loggedIn = user !== null
  if (!loggedIn || !user.slug) {
    return <Redirect to="/upcoming" />
  }
  return <Redirect to={`/host/${user.slug}`} />
}

export const HostProfile = () => {
  const { user } = useContext(UserContext)
  const userCanHostSessions = getUserCanHostSessions(user)
  const loggedIn = user !== null
  const { hostNameSlug } = useParams()

  const { result: hostProfileInfo, error, performFetch } = useAutomaticallyFetch(
    useGetHostProfile,
    { slug: hostNameSlug },
    {
      dependencies: [hostNameSlug],
      transform: data => {
        const imageData = { [data.hostUser.displayName]: getHostImageData(data.hostUser) }
        data.otherHosts.forEach(hostUser => {
          imageData[hostUser.displayName] = getHostImageData(hostUser)
        })
        return {
          hostId: data.hostUser.id,
          slug: data.hostUser.slug,
          displayName: data.hostUser.displayName,
          emojis: data.hostUser.emojis,
          fullOrDisplayName: data.hostUser.useFullNameForHostProfile ? data.hostUser.fullName : data.hostUser.displayName,
          imageData,
          shortCta: `${data.hostUser.shortCta || 'Co-work with'} ${data.hostUser.displayName}`,
          description: data.hostUser.bio,
          headline: data.hostUser.headline,
          hideInPublicDirectory: data.hostUser.hideInPublicDirectory,
          useFullNameForHostProfile: data.hostUser.useFullNameForHostProfile,
          location: data.hostUser.location,
          musicStyle: data.hostUser.musicStyle,
          musicTitles: data.hostUser.musicTitles,
          musicImages: data.hostUser.musicImages,
          flowActivities: data.hostUser.flowActivities,
          linkedin: data.hostUser.linkedin,
          twitter: data.hostUser.twitter,
          linkUrl: data.hostUser.linkUrl,
          linkTitle: data.hostUser.linkTitle,
          inProgressCertifications: data.hostUser.inProgressCertifications,
          completedCertifications: data.hostUser.completedCertifications,
          sessions: data.upcomingEvents.map(({ id, title, agenda, subtitle, start, userSessionStatus, hostId, guests, visibility, pomodoro, chatOnly, breakCheckIns, musicDescription, hostGoalDescription, hostGoalEmoji, eventTypeId, }) =>
          ({
            id,
            title,
            subtitle,
            agenda,
            start,
            userSessionStatus,
            hostId,
            guests,
            hostUser: data.hostUser,
            visibility,
            pomodoro,
            chatOnly,
            breakCheckIns,
            musicDescription,
            hostGoalDescription,
            hostGoalEmoji,
            eventTypeId,
          })
          ),
          stats: [
            {
              name: 'Sessions Hosted',
              value: data.hostUser.hostedSessions
            },
            {
              name: 'Total Sessions',
              value: data.hostUser.sessions
            },
            {
              name: 'Co-worked With',
              value: data.hostUser.numCoworkedWith
            },
            {
              name: 'Hours of Flow enabled',
              value: Math.floor(data.hostUser.hostedMinutes / 60)
            }
          ],
          otherHosts: data.otherHosts,
          inviteCode: data.hostUser.inviteCode,
          badges: {
            host: data.badges.badgeMembershipsByUser[data.hostUser.id] ?? [],
            viewer: user !== null ? (data.badges.badgeMembershipsByUser[user.uid] ?? []) : []
          },
          isFollowed: data.isFollowed,
          hideHostFollowButton: data.hostUser.preferences.hideHostFollowButton
        }
      }
    }
  )

  const refetchHostProfileData = () => performFetch({ slug: hostNameSlug })

  const mobileView = useMediaQuery(mobileViewMediaQuery)

  const hostDisplayName = useGetDisplayName(hostProfileInfo ? { displayName: hostProfileInfo.fullOrDisplayName , emojis: hostProfileInfo.emojis } : null)

  if (hostProfileInfo === null) {
    return (
      <>
        {error === null ? <MainLoading /> : <SadOctoPage />}
      </>
    )
  }

  const viewingAsHost = user !== null && user.uid === hostProfileInfo.hostId;

  const bannerFreeTrialLink = `/upcoming?inviteCode=${hostProfileInfo.inviteCode}`

  const headlineOrBioTeaser = hostProfileInfo.headline || truncateTextWithEllipses(hostProfileInfo.description, 100)


  return (
    <HostProfileInfoContext.Provider value={{ ...hostProfileInfo, hostDisplayName, refetchHostProfileData }}>
      <SegmentProvider label={'Host Profile'}>
        <div css={css`
          background-color: #F8F8F8;
          min-height: 100vh;
          padding-bottom: 40px;
        `}>
          <Helmet>
            <title>{hostDisplayName} on Flow Club</title>
            <meta name="description" content={headlineOrBioTeaser} />
            <meta name="twitter:description" content={headlineOrBioTeaser} />
            <meta property="og:image" content={getHostImageUrl(100, hostProfileInfo.imageData[hostProfileInfo.displayName])} />
            <meta name="twitter:image" content={getHostImageUrl(100, hostProfileInfo.imageData[hostProfileInfo.displayName])} />
            <link rel="canonical" href={`${baseURL}/host/${hostProfileInfo.slug}`} />
            {hostProfileInfo.hideInPublicDirectory && <meta name="robots" content="noindex" />}
          </Helmet>
          {!loggedIn &&
            <PurpleBanner>
              Join {hostDisplayName} on Flow Club! Book a session below, or <Link to={bannerFreeTrialLink}>browse over 2,000 weekly sessions.</Link>
            </PurpleBanner>
          }
          <Container css={css`
            ${mobileView && 'padding: 0px;'}
          `}>
            <div css={css`
              ${!mobileView && 'display: flex;'}
            `}>
              <HostBioAndSessions viewingAsHost={viewingAsHost} />
              {!mobileView && 
                <div>
                  {viewingAsHost && <ShareFlowClubLink />}
                  <MoreHostsList />
                </div>
              }
            </div>
            <FloatingCreateSessionButton userCanHostSessions={userCanHostSessions} />
          </Container>
        </div>
      </SegmentProvider>
    </HostProfileInfoContext.Provider>
  )
}

const ShareFlowClubLink = () => {

  const mobileView = useMediaQuery(mobileViewMediaQuery)
  const sendSegmentEvent = useSendSegmentEvent()
  const { inviteCode, slug } = useContext(HostProfileInfoContext)
  
  const absoluteInviteLink = `${baseURL}/h/${slug}?inviteCode=${inviteCode}`

  const mobileStyle = css`
    margin: 0px 0px 32px 0px;
    width: 100%;
  `

  return (
    <WhiteBackgroundSection customCss={css`
      width: 320px;
      margin: 0px 0px 36px 38px;
      padding: 20px 25px;
      ${mobileView && mobileStyle}
    `}>
      <h5>Share Flow Club</h5>
      <LightGrayText>Share your link with friends! They'll get a 14-day free trial (instead of 7). If they subscribe, you'll get a free month.</LightGrayText>
      <Button css={css`
        margin-top: 36px;
        width: 100%;
        font-size: 14px;
        height: 48px;
        letter-spacing: 1.25px;
      `}
        onClick={() => {
          copyToClipboard(absoluteInviteLink)
          sendSegmentEvent("Copied host profile link", {
            host: slug,
          })
        }}
      >Copy Link With Code</Button>
    </WhiteBackgroundSection>
  )
}

const HostBioAndSessions = ({ viewingAsHost }) => {
  const { hostDisplayName, displayName, headline, stats, sessions, badges, shortCta } = useContext(HostProfileInfoContext)
  const sessionsHosted = stats.find(stat => stat.name === 'Sessions Hosted').value || 0
  const newHost = sessionsHosted < 3
  const { user } = useContext(UserContext)
  const loggedIn = user !== null
  const mobileView = useMediaQuery(mobileViewMediaQuery)
  const sessionsRef = useRef(null)

  return (
    <div css={css`
      flex-grow: 1;
    `}>
      <WhiteBackgroundSection customCss={css`
        margin-bottom: ${!mobileView ? 36 : 20}px;
        overflow: hidden;
        position: relative;
      `}>
        <div>
          <BlueGradientBackground />
          <div css={css`
            padding: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 24px;
          `}>
            <div css={css`
              position: absolute;
              left: 176px;
              top: 8px;

              ${!mobileView && css`min-width: 400px; background: #fff;`}
            `}>
              <HeadlineInfo headline={headline} viewingAsHost={viewingAsHost} />
            </div>
            <div css={css`
              height: 38px;
              display: flex;
              justify-content: flex-end;
              ${!mobileView && css`margin-bottom: 50px;`}
            `}>
              {!mobileView && (loggedIn || sessions.length === 0) ?
                <FollowButton />
                : null
              }
            </div>
            {mobileView && <HostHeadline headline={headline} viewingAsHost={viewingAsHost} />}
            <HostBadges badges={badges} viewingAsHost={viewingAsHost} />
            <HostBio viewingAsHost={viewingAsHost} />
            {!mobileView && <>
              <LinksAndCertifications viewingAsHost={viewingAsHost} />
              {!newHost && <div css={css`border-top: 1px solid${NEUTRAL_40}; padding-top: 32px;`}>
                <HostStats stats={stats} hostName={hostDisplayName} />
              </div>}
            </>}
          </div>
        </div>
        <MainHostImage hostName={displayName} viewingAsHost={viewingAsHost} />
      </WhiteBackgroundSection>
      {mobileView && viewingAsHost && <ShareFlowClubLink />}
      <UpcomingSessions sessions={sessions} hostName={hostDisplayName} viewingAsHost={viewingAsHost} shortCta={shortCta} sessionsRef={sessionsRef} />
      {mobileView && <>
        {!newHost && <div css={css`border-top: 1px solid ${NEUTRAL_40}; padding: 32px 8px 0px 8px;`}>
          <HostStats stats={stats} hostName={hostDisplayName} />
        </div>}
        <LinksAndCertifications />
      </>}
    </div>
  )
}

const MainHostImage = ({ hostName, viewingAsHost }) => {
  const { user } = useContext(UserContext)
  const mobileView = useMediaQuery(mobileViewMediaQuery)
  const { refetchHostProfileData } = useContext(HostProfileInfoContext)
  const sendSegmentEvent = useSendSegmentEvent()
  const { setActiveModal } = useModal()
  const fileInputRef = useRef(null)
  const [loading, setLoading] = useState(false)


  const onCropComplete = async ({ imgSrc, scaleX, scaleY, crop, mimeType }) => {
    hideCropModal();
    setLoading(true);
    const newFile = await getCroppedImageFile({ user, imgSrc, scaleX, scaleY, crop, mimeType })
    const authToken = await getAuthToken();
    const { _result, error } = await uploadPhoto(authToken, newFile)
    sendSegmentEvent("Picture Submitted", { succeeded: error === null, error });
    if (error === null) {
       // Hack because we need to wait for firebase function that resizes the image as well
      setTimeout(() => {
        setLoading(false);
        toastSuccess({ message: "Picture successfully updated!" });
        refetchHostProfileData();
      }, 2000);
     
    } else {
      toastError({ message: error });
    }
  };

  const showCropModal = (imageSrc, mimeType) => {
    setLoading(false)
    setActiveModal(CircularImageCropModal, { imgSrc: imageSrc, onCropComplete, onCancel: hideCropModal, mimeType })
  }
  const hideCropModal = () => {
    setActiveModal(null)
  }

  const getAuthToken = useAuthToken()

  const uploadPictureClicked = () => {
    if (loading) { return }

    if (fileInputRef.current !== null) {
      fileInputRef.current.click()
    }
  }

  return (
    <>
      <div css={css`
        position: absolute;
        left: 32px;
        top: ${mobileView ? '30px' : '60px'};
        border: 4px solid white;
        border-radius: 50%;
      `}>
          <HostImage size={mobileView ? 96 : 128} hostName={hostName} />
          {viewingAsHost &&
            <div onClick={uploadPictureClicked} css={css`
              position: absolute;
              top: -8px;
              right: -8px;
              height: 44px;
              width: 44px;
              background-color: rgba(255, 255, 255, 0.8);
              border: 1px solid ${NEUTRAL_50};
              border-radius: 100%;
              &:hover { background-color: ${FC_LIGHT_BLUE}; }


              display: flex;
              justify-content: center;
              align-items: center;
            `}>
              {loading ? <LoadingIndicator /> : <EditIcon css={css`display: flex; justify-content: center; align-items: center;`} />}
            </div>
          }
      </div>
      <input type="file" accept="image/png, image/jpeg" ref={fileInputRef} onChange={(event) => {
        setLoading(true)
        showCropModalForFileSelected(event, showCropModal)
      }} css={css`display: none;`} />
    </>
  )
}

const HostHeadline = ({ headline, viewingAsHost }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: updateHost, fetching: loading } = useUpdateHost()
  const { refetchHostProfileData } = useContext(HostProfileInfoContext)

  const [editingState, setEditingState] = useState({ editing: false, text: headline })
  const { editing, text } = editingState

  const editClicked = () => {
    sendSegmentEvent("Headline Edit Button Clicked", { oldHeadline: headline })
    setEditingState({ editing: true, text: headline })
  }
  const cancelClicked = () => {
    sendSegmentEvent("Headline Edit Cancel Button Clicked", { oldHeadline: headline, newHeadline: text })
    setEditingState({ editing: false, text: headline })
  }
  const saveClicked = async () => {
    sendSegmentEvent("Headline Edit Save Button Clicked", { oldHeadline: headline, newHeadline: text })

    if (loading) { return }

    const { success, error } = await updateHost({ updateObject: { headline: text } })
    sendSegmentEvent("Headline Submitted", { succeeded: success, error })
    if (success) {
      await refetchHostProfileData()
      toastSuccess({ message: "Headline successfully updated!" })
      setEditingState({ editing: false, text })
    } else {
      toastError({ message: error })
    }
  }

  const headlineOrDescriptionForHost = headline || (viewingAsHost ? "Add a headline — what are you working on?" : "")

  return (
    <div css={css`display: flex; align-items: center;`}>
      {!editing ? (
        <div css={css`display: flex; justify-content: space-between; width: 100%;`}>
          <Text style={TEXT_STYLES.APP_H6}>
            {headlineOrDescriptionForHost}
          </Text>
          {viewingAsHost && <div css={css`
            width: 44px;
            height: 44px;
            border-radius: 100%;
            background-color: ${FC_LIGHTER_BLUE};
            &:hover { background-color: ${FC_LIGHT_BLUE}; }
            cursor: pointer;
          `}>
            <EditIcon onClick={editClicked} />
          </div>
          }
        </div>
      ) : (
        <div css={css`width: 100%; display: flex; flex-direction: column; gap: 8px; align-items: flex-start;`}>
          <TextArea
            placeholder={"In 70 characters— what are you working on?"}
            maxLength={70}
            wrapperCustomCss={css`width: 100%;`}
            value={text}
            onChange={event => setEditingState(editingState => ({ ...editingState, text: event.target.value }))}
          />
          <div css={css`display: flex; gap: 8px; justify-content: flex-end; width: 100%;`}>
            <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={cancelClicked}>Cancel</FlowButton>
            <FlowButton loading={loading} onClick={saveClicked}>Save</FlowButton>
          </div>
        </div>
      )}
    </div>
  )
}

const MAX_BIO_CHARACTERS_MOBILE = 80
const MAX_BIO_CHARCTERS_DESKTOP = 140

const HostBio = ({ viewingAsHost }) => {
  const mobileView = useMediaQuery(mobileViewMediaQuery)
  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: updateHost, fetching: loading } = useUpdateHost()
  const { refetchHostProfileData, description, location, musicImages, musicTitles, musicStyle, flowActivities } = useContext(HostProfileInfoContext)

  const musicTitlesStr = musicTitles ? _.isArray(musicTitles) ? musicTitles.join(', ') : musicTitles : null;

  const hasLocationMusicOrFlowActivities = location || musicImages || musicTitlesStr || flowActivities;

  const [editingState, setEditingState] = useState({ 
    editing: false, 
    bio: description,
    musicStyle,
    musicTitles: musicTitlesStr,
    flowActivities,
    location
  })
  
  const { editing, text } = editingState
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  const MAX_BIO_CHARACTERS = mobileView ? MAX_BIO_CHARACTERS_MOBILE : MAX_BIO_CHARCTERS_DESKTOP;
  const descriptionExceedsCharLimit = description !== null && description.length > MAX_BIO_CHARACTERS;

  const expandable = mobileView ? (descriptionExceedsCharLimit || hasLocationMusicOrFlowActivities) : descriptionExceedsCharLimit

  const displayText = expandable && !expanded ? truncateTextWithEllipses(description, MAX_BIO_CHARACTERS) : description;

  const editClicked = () => {
    sendSegmentEvent("Bio Edit Button Clicked")
    setEditingState({ 
      editing: true, 
      bio: description,
      musicStyle,
      musicTitles: musicTitlesStr,
      flowActivities,
      location
    })
  }
  
  const cancelClicked = () => {
    sendSegmentEvent("Bio Edit Cancel Button Clicked")
    setEditingState({ 
      editing: false, 
      bio: description,
      musicStyle,
      musicTitles: musicTitlesStr,
      flowActivities,
      location
    })
  }
  const saveClicked = async () => {
    sendSegmentEvent("Bio Edit Save Button Clicked")

    if (loading) { return }
    
    const { success, error } = await updateHost({ 
      updateObject: { 
        bio: editingState.bio,
        musicStyle: editingState.musicStyle,
        musicTitles: editingState.musicTitles,
        flowActivities: editingState.flowActivities,
        location: editingState.location
      } 
    })
    sendSegmentEvent("Bio Submitted", { succeeded: success, error })
    if (success) {
      await refetchHostProfileData()
      toastSuccess({ message: "Profile successfully updated!" })
      setEditingState({ ...editingState, editing: false })
    } else {
      toastError({ message: error })
    }
  }

  return (
    <div css={css`display: flex; flex-direction: column;`}>
    <div css={css`display: flex; align-items: center;`}>
      {!editing ? (
        <div css={css`display: flex; justify-content: space-between; width: 100%;`}>
          <LightGrayText customCss={css`max-width: 576px;`}>
            {displayText}
            {expandable && <LinkStyledText customCss={css`
              margin-left: 8px;
            `} onClick={toggleExpand}>
              {expanded ? 
              "See Less" :
              "See More"
              }
            </LinkStyledText>}
          </LightGrayText>
          {viewingAsHost && <div css={css`
            width: 44px;
            height: 44px;
            border-radius: 100%;
            background-color: ${FC_LIGHTER_BLUE};
            &:hover { background-color: ${FC_LIGHT_BLUE}; }
            cursor: pointer;
          `}>
            <EditIcon onClick={editClicked} />
          </div>
          }
        </div>
      ) : (
        <div css={css`width: 100%; display: flex; flex-direction: column; gap: 8px; align-items: flex-start;`}>
        <TextArea placeholder={`Write something about yourself...
Join Amy, recovering actor and sales ops leader based in Ohio. Amy uses Flow Club as a flywheel to get started, especially for the work she has been avoiding. Ask her about reading a book a week for the last 3 years.`} wrapperCustomCss={css`width: 100%;`} value={editingState.bio} onChange={event => setEditingState({...editingState, bio: event.target.value})} />
        <div css={css`display: flex; flex-direction: row; align-items: center; gap: 4px;`}>📍<TextInput value={editingState.location} onChange={event => setEditingState({...editingState, location: event.target.value})} placeholder="Location" />
        </div>
        <div css={css`display: flex; flex-direction: row; align-items: center; gap: 4px;`}>
          🎧
          <Form.Control
            as="select"
            placeholder="Will you play music when you host?"
            value={editingState.musicStyle || ''}
            onChange={event => {
              setEditingState({...editingState, musicStyle: event.target.value});
            }}
          >
            <option value="" disabled>Will you play music when you host?</option>
            {Object.entries(MUSIC_STYLES).map(([value, name]) => (
              <option key={name} value={name}>{name}</option>
            ))}
          </Form.Control>
        </div>
        {editingState.musicStyle !== MUSIC_STYLES.NO_MUSIC &&
        <div css={css`display: flex; flex-direction: row; align-items: center; gap: 4px;`}>
          🎶<TextInput value={editingState.musicTitles} onChange={event => setEditingState({...editingState, musicTitles: event.target.value})} placeholder="Favorite flow music" />
        </div>}
        <div css={css`display: flex; flex-direction: row; align-items: center; gap: 4px;`}>🌊<TextInput value={editingState.flowActivities} onChange={event => setEditingState({...editingState, flowActivities: event.target.value})} placeholder="Flow activities" /></div>
        <div css={css`display: flex; gap: 8px; justify-content: flex-end; width: 100%;`}>
          <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={cancelClicked}>Cancel</FlowButton>
          <FlowButton loading={loading} onClick={saveClicked}>Save</FlowButton>
        </div>
      </div>
      )}
      </div>
      {(!mobileView || expanded) && !editing  &&
      <div>
        {location && <>📍 {location}<br/></>}
        {musicStyle && <>🎧 {musicStyle}<br/></>}
        {musicStyle !== MUSIC_STYLES.NO_MUSIC && musicTitlesStr && <>🎶 {musicTitlesStr}<br/></>}
        {musicStyle !== MUSIC_STYLES.NO_MUSIC && musicImages && musicImages.length > 0 ?
          <div css={css`display: flex; gap: 4px;`}>
            {musicImages.map((image, index) => <img key={index} src={image} css={css`
              width: ${mobileView ? 64: 100}px;
              height: ${mobileView ? 64: 100}px;
              flex-wrap: ${mobileView ? 'nowrap' : 'wrap'};
              ${mobileView && css`overflow-x: auto;`}
            `}/>)}
          </div>
          : null
        }
        {flowActivities && <>🌊 {flowActivities}</>}
      </div>
      }
    </div>
  )
}

const HeadlineInfo = ({ headline, viewingAsHost }) => {
  const { hostDisplayName } = useContext(HostProfileInfoContext)
  const mobileView = useMediaQuery(mobileViewMediaQuery)

  return (
    <div>
      <h4>{hostDisplayName}</h4>
      {!mobileView && <HostHeadline headline={headline} viewingAsHost={viewingAsHost}/>}
    </div>
  )
}

const FavoriteIcon = ({ isFavorite }) => {
  return (
    <Icon css={css`fill: ${isFavorite ? FC_DARK_BLUE : NEUTRAL_20}; width: 16px; height: 16px;`} type={TYPE_STAR} />
  )
}

export const FollowButton = ({ }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()

  const { displayName, hostId, isFollowed, refetchHostProfileData, hideHostFollowButton } = useContext(HostProfileInfoContext)

  const [followedViaEmailData, setFollowedViaEmailData] = useState({ followed: false, email: null })
  const { setActiveModal } = useModal()
  const { performFetch: followHost, fetching: followHostLoading } = useFollowHost()
  const followButtonClicked = async () => {
    sendSegmentEvent("Follow Button Clicked")
    if (user !== null) {
      const { success, error } = await followHost({ hostId })
      if (success) {
        refetchHostProfileData()
      } else {
        toastError({ message: error })
      }
    } else {
      setActiveModal(FollowHostViaEmailModal, { hostDisplayName: displayName, hostId, setFollowedViaEmailData })
    }
  }

  const { performFetch: unfollowHost, fetching: unfollowHostLoading } = useUnfollowHost()
  const unfollowButtonClicked = async () => {
    sendSegmentEvent("Unfollow Button Clicked")
    const { success, error } = await unfollowHost({ hostId, ...(user !== null ? {} : { followerEmail: followedViaEmailData.email }) })
    if (success) {
      refetchHostProfileData()
      setFollowedViaEmailData({ followed: false, email: null })
    } else {
      toastError({ message: error })
    }
  }

  const [unfollowMousedOver, setUnfollowMousedOver] = useState(false)

  const hostAlreadyFollowed = isFollowed || followedViaEmailData.followed

  if ((user !== null && user.uid === hostId) || hideHostFollowButton) {
    return null
  }
  const followActionName = user !== null ? 'Favorite' : 'Follow'
  const followingActionName = user !== null ? 'Favorited' : 'Following'
  const unfollowActionName = user !== null ? 'Unfavorite' : 'Unfollow'

  return (
    !hostAlreadyFollowed ?
      <TooltipWrapper TooltipContents={`You'll receive a calendar invite whenever ${displayName} creates a Flow Club session. We'll also notify ${displayName} of your follow.`} disabled={user === null} useFloatingArgs={{ placement: 'bottom-end' }}>
        <FlowButton onClick={followButtonClicked} loading={followHostLoading}>
          <FavoriteIcon />
          {followActionName}
        </FlowButton>
      </TooltipWrapper> :
      <FlowButton
        customCss={css`width: 150px; padding: 11px 4px;`}
        buttonStyle={unfollowMousedOver ? BUTTON_STYLES.PRIMARY : BUTTON_STYLES.OUTLINE_DARK}
        onClick={unfollowButtonClicked}
        loading={unfollowHostLoading}
        onMouseEnter={() => setUnfollowMousedOver(true)}
        onMouseLeave={() => setUnfollowMousedOver(false)}
      >
        <FavoriteIcon isFavorite={!unfollowMousedOver} />
        {unfollowMousedOver ? unfollowActionName : followingActionName}
      </FlowButton>
  )
}

const LinksAndCertifications = ({ viewingAsHost }) => {
  const { linkedin, twitter, linkUrl, linkTitle, inProgressCertifications, completedCertifications, refetchHostProfileData } = useContext(HostProfileInfoContext)
  const { setActiveModal } = useModal()

  const showUpdateNameModal = () => {
    setActiveModal(UpdateNameModal, { title: "Update your info", successFn: refetchHostProfileData })
  }

  const sendSegmentEvent = useSendSegmentEvent()
  const linkedinIconLinkClicked = () => {
    sendSegmentEvent("Linkedin Icon Link Clicked", { linkedin, from: "hostProfile" })
  }
  const twitterIconLinkClicked = () => {
    sendSegmentEvent("Twitter Icon Link Clicked", { twitter, from: "hostProfile" })
  }
  const linkClicked = () => {
    sendSegmentEvent("Link Clicked", { linkUrl, linkTitle, from: "hostProfile" })
  }

  const hasAtLeastOneLinkOrCertification = (linkedin !== null || twitter !== null || (linkTitle !== null && linkUrl !== null)) || inProgressCertifications !== null || completedCertifications !== null

  if (!hasAtLeastOneLinkOrCertification && !viewingAsHost) {
    return null
  }

  return (
    <div>
      {viewingAsHost && !hasAtLeastOneLinkOrCertification &&
        <LinkStyledText onClick={showUpdateNameModal}>Share your links and certifications</LinkStyledText>
      }
      {inProgressCertifications !== null &&
      <div css={css`margin-bottom: 16px;`}>
        🔜📜 {inProgressCertifications}
      </div>
      }
      {completedCertifications !== null &&
      <div css={css`margin-bottom: 16px;`}>
        ✅📜 {completedCertifications}
      </div>
      }
      {linkedin !== null &&
        <a onClick={linkedinIconLinkClicked} href={linkedin} target="_blank" rel="noreferrer">
          <Icon css={css`fill: white; &:hover { fill: ${FC_LIGHT_BLUE}; }`} type={TYPE_LINKEDIN_CIRCLE} />
        </a>
      }
      {twitter !== null &&
        <a onClick={twitterIconLinkClicked} href={twitter} target="_blank" rel="noreferrer">
          <Icon css={css`fill: white; &:hover { fill: ${FC_LIGHT_BLUE}; }`} type={TYPE_TWITTER_CIRCLE} />
        </a>
      }
      {linkTitle !== null && linkUrl !== null &&
        <a onClick={linkClicked} href={linkUrl} target="_blank" rel="noreferrer nofollow me">
          <span css={css`
              height: 20px;
              padding: 4px;
              &:hover { background: ${FC_LIGHT_BLUE}; }
              border-radius: ${linkTitle === null ? '50%' : '10px'};
              color: #757575;
              cursor: pointer;
            `}>
          <Icon
            css={css`
              width: 20px;
              height: 20px;
              margin-right: 4px;
              fill: white;
              &:hover { fill: ${FC_LIGHT_BLUE}; }
            `}
            type={TYPE_LINK}
          />{linkTitle}
          </span>
        </a>
      }
      {viewingAsHost &&
        <span css={css`
          margin-left: 8px;
          border-radius: 100%;
          background-color: ${FC_LIGHTER_BLUE};
          &:hover { background-color: ${FC_LIGHT_BLUE}; }
          cursor: pointer;
        `}>
          <EditIcon onClick={showUpdateNameModal} buttonCss={css`padding:3px;`} iconCss={css`width:16px; height: 16px; margin-bottom: 8px;`} />
        </span>
      }
    </div>
  )
}

const HostBadges = ({ badges, viewingAsHost }) => {
  const viewerBadgeIds = new Set(badges.viewer.map(badge => badge.badgeId))

  const mobileView = useMediaQuery(reallySuperDuperMobileViewMediaQuery)
  const hostBadgesWithViewerSharedData = sortBy(
    badges.host.map(badge => ({ ...badge, viewerShared: viewerBadgeIds.has(badge.badgeId) })),
    'viewerShared',
    true
  )
  
  return (
    <div css={css`
      display: flex;
      flex-wrap: ${mobileView ? 'nowrap' : 'wrap'};
      ${mobileView && css`overflow-x: auto;`}
      gap: ${mobileView ? '4px': '16px 12px'};
      user-select: none;
    `}>
      {hostBadgesWithViewerSharedData.map(badge => {
        return (
          <div key={badge.id} css={css`
            border: 1px solid transparent;
            border-radius: ${mobileView ? '8px' : '32px'};
            background-color: ${NEUTRAL_20};
            color: black;
            padding: ${mobileView ? '2px 4px 2px 3px': '4px 8px 4px 6px'};
            ${!viewingAsHost && badge.viewerShared && css`
              border-color: ${FC_BLUE};
              color: ${FC_BLUE};
              font-weight: bold;
            `}
            display: flex;
            gap: ${mobileView ? '4px' : '8px'};
            white-space: nowrap;
          `}>
            <Text customCss={css`font-size: ${mobileView ? '14px' : '18px'};`}>{badge.emoji}</Text>
            <Text>{badge.name}</Text>
          </div>
        )
      })}
    </div>
  )
}

const HostStats = ({ hostName, stats }) => {
  const mobileView = useMediaQuery(reallySuperDuperMobileViewMediaQuery)

  return (
    <div>
      <h5 css={css`margin-bottom: ${!mobileView ? 24 : 4}px;`}>{hostName}'s Flow Club Activity</h5>
      <div css={css`display: ${!mobileView ? 'flex' : ''};`}>
        {stats.map((stat, index) => <StatDisplay stat={stat} key={stat.name} isFirst={index === 0} />)}
      </div>
    </div>
  )
}

const StatDisplay = ({stat, isFirst}) => {
  const { name, value } = stat

  const mobileView = useMediaQuery(reallySuperDuperMobileViewMediaQuery)

  return (
    <div css={css`
      margin-left: ${isFirst || mobileView ? 0 : 48}px;
      margin-top: ${mobileView && '16px'};
    `}>
      <h2>{value}</h2>
      <DarkText customCss={css`
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      `}>{name}</DarkText>
    </div>
  )
}


const BlueGradientBackground = () => {
  const mobileView = useMediaQuery(mobileViewMediaQuery);
  return (
  <div css={css`
    background-color: ${FC_LIGHT_BLUE};
    background-image: linear-gradient(
      to left, ${FC_LIGHTER_BLUE}, ${FC_LIGHT_BLUE}
    );
    height: ${mobileView ? '60px' : '120px'};
  `}></div>
  )
}

const UpcomingSessions = ({ sessions, hostName, viewingAsHost, shortCta, sessionsRef }) => {
  const mobileView = useMediaQuery(mobileViewMediaQuery)
  const sessionsByDay = sessions.reduce((sessionsByDay, session) => {
    const date = dayjs(session.start).startOf('day').unix()
    if (sessionsByDay[date] === undefined) { sessionsByDay[date] = [] }
    sessionsByDay[date].push(session)
    return sessionsByDay
  }, {})
  const days = sortBy(Object.keys(sessionsByDay).map(date => ({ date, sessions: sortBy(sessionsByDay[date], "start") })), "date")

  const upcomingSessionsExist = days.length > 0
  const ctaPrefix = sessions.length > 0 ? `${sessions.length} chance${sessions.length === 1 ? '' : 's'} to ` : ''
  const ctaPostfix = sessions.length > 0 ? ` (${sessions.length})` : ''

  return (
    <div ref={sessionsRef}>
      <h5 css={css`margin-bottom: ${upcomingSessionsExist && !mobileView ? 28 : 16}px; margin-left: ${mobileView ? '16px' : '32px'}; ${mobileView && css`font-size: 20px;`}`}>{viewingAsHost ? 'Your Upcoming Sessions' : `${shortCta}`}{ctaPostfix}</h5>
        {days.map((day) => <SessionCardGroup key={day.date} day={day} />)}
        <LinkToScheduleOrCreate
          hostName={hostName}
          viewingAsHost={viewingAsHost}
          upcomingSessionsExist={upcomingSessionsExist}
        />
    </div>
  )
}

const LinkToScheduleOrCreate = ({ hostName, viewingAsHost, upcomingSessionsExist }) => {
  return (
    <WhiteBackgroundSection customCss={css`
      padding: 48px 44px 44px 44px;
      text-align: center;
    `}>
      <h5 css={css`margin-bottom: 12px;`}>
        {viewingAsHost ?
        (upcomingSessionsExist ?
          'Add more sessions for people to co-work with you' :
          'No upcoming sessions'
        ) :
        (upcomingSessionsExist ?
          `Can't make any of ${hostName}'s sessions?` :
          `${hostName} hasn't scheduled any upcoming sessions`
        )
        }
      </h5>
      <DarkText>
        {viewingAsHost ?
        (upcomingSessionsExist ?
          '':
          'You have no upcoming sessions scheduled.'
        ) :
        'View the Flow Club schedule to see available sessions from all hosts.'
        }
      </DarkText>
      <Link to={`/upcoming${viewingAsHost ? '/create/' : ''}`}>
        <Button css={css`
          margin-top: 16px;
          font-size: 14px;
          letter-spacing: 1.25px;
        `}>{viewingAsHost ? 'Create a Session' :'View the Schedule'}</Button>
      </Link>
    </WhiteBackgroundSection>
  )
}

const SessionCardGroup = ({ day }) => {
  const mobileView = useMediaQuery(mobileViewMediaQuery)
  const { refetchHostProfileData } = useContext(HostProfileInfoContext)
  const { date, sessions } = day


  const dateFormatted = dayjs.unix(date).format("ddd, MMM D")
  
  return (
    <div css={css`margin-bottom: ${mobileView ? '16px' : '32px'};`}>
      <h5 css={css`text-align: center; ${mobileView && css`font-size: 20px;`}`}>{dateFormatted}</h5>
      {sessions.map((session, index) => <EventCard
        key={session.id}
        isFirst={index === 0}
        isLast={index === sessions.length - 1}
        event={session}
        showToolTip={false}
        onActionSuccess={refetchHostProfileData}
      />)}
    </div>
  )
}

const MoreHostsList = () => {
  const { otherHosts: hostUsers } = useContext(HostProfileInfoContext)
  return (
    <div css={css`margin-left: 63px; flex-shrink: 0;`}>
      <h6 css={css`margin-bottom: 4px;`}>More Flow Club Hosts</h6>
      {hostUsers.map(hostUser => <HostLink key={hostUser.id} hostUser={hostUser} />)}
      <div css={css`margin-top: 16px;`}>
        <Link to="/hosts/">View All Hosts</Link>
      </div>
    </div>
  )
}

const HostLink = ({ hostUser }) => {
  const { displayName, slug } = hostUser
  return (
    <div css={css`display: flex; align-items: center; margin-top: 16px;`}>
      <HostImage customCss={css`
        margin-right: 12px;
      `} size={48} hostName={displayName}/>
      <Link to={`/host/${slug}`}>{displayName}</Link>
    </div>
  )
}

const HostImage = ({ hostName, customCss = '', size = 42 }) => {
  const { imageData } = useContext(HostProfileInfoContext)
  const imageSource = imageData[hostName] !== undefined ? getHostImageUrl(size, imageData[hostName]) : null
  return (
    <img
      css={css`
        width: ${size}px;
        height: ${size}px;
        border-radius: 50%;
        ${customCss}
      `}
      src={imageSource}
      alt={hostName}
    />
  )
}

const FollowHostViaEmailModal = ({ onHide, hostDisplayName, hostId, setFollowedViaEmailData }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: followHost, fetching } = useFollowHost()
  const onSubmit = async (event) => {
    sendSegmentEvent("Follow Via Email Form Submitted")
    event.preventDefault()
    const { success, error } = await followHost({ hostId, followerEmail: email, followerName: name })
    if (success) {
      setFollowedViaEmailData({ followed: true, email })
      onHide()
    } else {
      toastError({ message: error })
    }
  }
  const submissionEnabled = name.length > 0 && email.length > 0

  return (
    <Modal onClose={onHide}>
      <form onSubmit={onSubmit}>
        <div css={css`max-width: 500px; padding: 32px; display: flex; flex-direction: column; gap: 16px;`}>
          <Text style={TEXT_STYLES.MARKETING_H4}>Follow via email</Text>
          <Text>You'll receive a calendar invite whenever {hostDisplayName} creates a Flow Club session. We'll also notify {hostDisplayName} of your follow.</Text>
          <TextInput label={'Your name'} value={name} onChange={(event) => setName(event.target.value)} />
          <TextInput label={'Your email'} value={email} onChange={(event) => setEmail(event.target.value)} type={TEXT_INPUT_TYPES.EMAIL} />
          <div css={css`display: flex; justify-content: flex-end; gap: 16px; margin-top: 16px;`}>
            <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={onHide}>Cancel</FlowButton>
            <FlowButton type="submit" loading={fetching} disabled={!submissionEnabled}>Follow</FlowButton>
          </div>
        </div>
      </form>
    </Modal>
  )
}
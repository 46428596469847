

import firebase from 'firebase/compat/app';
import { useContext } from 'react';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { useSignupWithoutBooking } from '../fetch/endpoints';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { useLoginSuccessCallback } from './useLoginSuccessCallback';
import { FIREBASE_AUTH_METHODS } from './useLogin';
import { UserContext } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import * as Sentry from "@sentry/react";

export const useEmailPasswordSignup = ({ onSuccess = () => { }, onFailure = () => { } }) => {
  const { inviteCode, guestPass } = useContext(InviteInfoContext);
  const { preSignupUserData, setLoginInProgress } = useContext(UserContext);
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: signupWithoutBooking } = useSignupWithoutBooking()
  const setLoggedInSuccessResult = useLoginSuccessCallback(onSuccess, "signup")
  const createFirestoreUser = async (authUser, firstName, lastName) => {
    const { email, uid: userId } = authUser
    const displayName = `${firstName} ${lastName}`
    const { success, error } = await signupWithoutBooking({ displayName, email, userId, inviteCode, guestPassId: guestPass ? guestPass.id : null, preSignupUserData })
    if (success) {
      sendSegmentEvent("Signed up for trial", {authMethod: 'Email/Password'})
      setLoggedInSuccessResult(authUser)
    } else {
      console.error(`Error creating firestore user after successful account-creation auth – deleting newly created firebase auth user (id ${userId}) in order to allow clean retry`)
      console.error(error)
      Sentry.captureException(error, { extra: { displayName, email, userId } });
      await firebase.auth().currentUser.delete()
      onFailure(`There was an error creating your account: ${error}`)
    }
  }

  const attemptSignup = async (email, password, firstName, lastName) => {
    try {
      setLoginInProgress(true)
      const signInResult = await firebase.auth().createUserWithEmailAndPassword(email, password)
      const user = signInResult.user
      if (user === null) {
        onFailure("There was an error creating your account. Please try again.")
        return
      }
      await user.sendEmailVerification()
      sendSegmentEvent("User Signed Up", { accountExisted: !signInResult.additionalUserInfo.isNewUser, firebaseAuthMethod: FIREBASE_AUTH_METHODS.EMAIL_PASSWORD })
      await createFirestoreUser(signInResult.user, firstName, lastName)

      onSuccess("Please check your email and verify your account to complete the signup process.")
    } catch (error) {
      onFailure(translateAuthErrorCodeToMessage(error.code))
    }
  }

  return attemptSignup
}
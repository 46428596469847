/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { DarkText, FlowButton, SIZE_PRESETS, TextInput } from '../components/flowComponents';
import { EntryPage } from './EntryPage';
import firebase from 'firebase/compat/app';
import { useQuery } from '../utils';
import { baseURL } from '../firebase';
import { Link } from 'react-router-dom';
import { BANNER_STYLES } from '../components/banners';

export const ResetPassword = () => {

  const query = useQuery();
  const redirectAfterLogin = query.get('redirect')

  const resettingPasswordError = query.get('error')
  const initialErrorMessage = resettingPasswordError === 'invalid-link' ? 'This reset password link is invalid — it may have been copied incorrectly, or it may have expired.' : null

  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState(initialErrorMessage)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      setError(null)
      // return value is undefined on success
      await firebase.auth().sendPasswordResetEmail(email, redirectAfterLogin ? { url: `${baseURL}${redirectAfterLogin}` } : undefined)
      setEmailSent(true)
    } catch (error) {
      setError(error.message)
    }
    setLoading(false)
  }


  return (
    !emailSent ? <EnterEmailField onSubmit={onSubmit} loading={loading} email={email} setEmail={setEmail} bannerContent={error} /> :
                <EmailSent email={email}/>
  )
}

const EnterEmailField = ({ onSubmit, loading, email, setEmail, bannerContent }) => {
  const disabled = email.length <= 0

  return (
    <EntryPage bannerContent={bannerContent} bannerStyle={BANNER_STYLES.error}>
      <div>
        <form onSubmit={onSubmit}>
          <h2 css={css`margin-bottom: 28px;`}>Recover Password</h2>
          <TextInput sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`margin-bottom: 8px;`} placeholder='mcsikszentmihalyi@gmail.com' value={email} onChange={event => setEmail(event.target.value)} label={'Email'}></TextInput>
          <FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY} disabled={disabled} loading={loading} onClick={onSubmit}>Send Link</FlowButton>
        </form>
      </div>
    </EntryPage>
  )
}

const EmailSent = ({ email }) => {
  return (
    <EntryPage>
      <div>
        <h2 css={css`margin-bottom: 20px;`}>Check Your Email</h2>
        <DarkText customCss={css`margin-bottom: 32px;`}>We've sent a link to <span css={css`font-weight: 700;`}>{email}</span> that will allow you to change your password.</DarkText>
        <Link to="/login"><FlowButton fillAvailableWidth sizePreset={SIZE_PRESETS.CHUNKY}>Log In</FlowButton></Link>
      </div>
    </EntryPage>
  )
}
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { ActiveSession } from './ActiveSession';
import { useFirestoreSubscribe } from '../firebase/helpers';
import { UserContext, usePreferences, getDisplayName } from '../UserProvider';
import { SadOctoPage } from '../commonLayout/SadOctoPage';
import { MainLoading } from '../commonLayout/MainLoading';
import { SegmentProvider } from '../wrappers/SegmentProvider';
import { arrObjectsToObject } from '../../functions/shared/helpers';
import { useAutomaticallyFetch } from '../fetch/helpers';
import { useGetBadgesForUsers, useGetEventDetails } from '../fetch/endpoints';
import { EMPTY_HOST_ID } from '../Sessions/SessionUtils';
import { DailyProvider } from '@daily-co/daily-react'
import DailyIframe from '@daily-co/daily-js';
import { ONE_MINUTE, USER_SESSION_STATUS } from '../../functions/shared/constants';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { EnterNameModal } from './EnterNameModal';
import { useSessionTimer } from './useSessionTimer';
import { ConfettiProvider } from '../wrappers/ConfettiProvider';

const ParticipantApp = ({ enableSignuplessJoining = false }) => {
  const { sessionId } = useParams();

  const { user } = useContext(UserContext)
  const { hideDisplayNameEmojis} = usePreferences()
  const { lastGoodResult: event, error, performFetch } = useAutomaticallyFetch(
    useGetEventDetails,
    { eventId: sessionId },
    {
      transform: result =>
      ({
        ...result.event,
        start: new Date(result.event.start),
        end: new Date(result.event.end),
        sessionName: result.event.hostId !== EMPTY_HOST_ID ?
          `${result.event.title} w/ ${getDisplayName({
            displayName: result.event.hostUser.displayName,
            emojis: result.event.hostUser.emojis,
            hideDisplayNameEmojis,
          })}` :
          result.event.title
      }),
      refetchEvery: user !== null ? ONE_MINUTE / 4 : null
    }
  )
  const eventLoading = event === null && error === null

  const refetchEventData = () => performFetch({ eventId: sessionId })

  if (eventLoading) {
    return <MainLoading/>
  }

  if (event === null) {
    return <SadOctoPage/>
  }
  
  return (
    <div className="App">
      <Helmet>
        <title>{!eventLoading ? event.sessionName : "Join a Flow Club Session"}</title>
      </Helmet>
      {!eventLoading && <SessionDataLoader event={event} refetchEventData={refetchEventData} enableSignuplessJoining={enableSignuplessJoining} />}
    </div>
  )
}

const SessionDataLoader = ({ event, refetchEventData, enableSignuplessJoining }) => {
  const { user, loadingAuthState: loadingUserLoggedInStatus } = useContext(UserContext)
  
  const [participants, participantsLoading] = useFirestoreSubscribe({
    functionalQuery: db =>
      db.collection('participants')
        .where('eventId', '==', event.id)
        .where('status', '!=', 'canceled'),
    fields: [
      "id", "goals", "goal", "userId", "joined", "name", "chatColorIndex",
      "phoneFreeOpened", "phoneFreeStarted", "phoneFreeEnded", "phoneFreeLastActive",
    ]
  })

  const participantUserIds = useMemo(() => participants.map(participant => participant.userId), [participants])
  const participantsById = useMemo(() => arrObjectsToObject(participants, "userId"), [participants])
    const participantCount = useMemo(() => Object.keys(participants).length, [participants]);

  const [chatHistory] = useFirestoreSubscribe({
    functionalQuery: db =>
      db.collection('chatMessages')
        .where('eventId', '==', event.id)
        .orderBy("sentAt", "asc"),
    fields: ["message", "sender", "id", "sentAt"],
    transform: chatHistory => chatHistory.map(message => ({...message, sentAt: message.sentAt.toDate().getTime()}))
  })
  const [chatMessageAttachments] = useFirestoreSubscribe({
    functionalQuery: db =>
      db.collection('chatMessageAttachments')
        .where('eventId', '==', event.id)
        .orderBy("sentAt", "asc"),
    fields: ["value", "baseMessageId", "sender", "id"]
  })


  let { result: badgeMembershipsByUser } = useAutomaticallyFetch(useGetBadgesForUsers, { userIds: participantUserIds }, { transform: result => result.badgeMembershipsByUser, dependencies: [participantCount], condition: !participantsLoading })
  if (badgeMembershipsByUser === null) { badgeMembershipsByUser = {} }

  const { start: startTime, agenda: stages } = event
  const { sessionFinished } = useSessionTimer({
    startTime: startTime,
    stages: stages,
    active: false
  })

  const notSignedIn = user === null
  const extremelyUnauthedUserEligible = notSignedIn && !sessionFinished && enableSignuplessJoining

  const { setActiveModal } = useModal()
  useEffect(() => {
    (async () => {
      if (extremelyUnauthedUserEligible) {
        setActiveModal(EnterNameModal)
      }
    })();
  }, [extremelyUnauthedUserEligible])

  if (extremelyUnauthedUserEligible) {
    return <MainLoading />
  }

  const userIsLoggedInButParticipantsStillLoading = user && participantsLoading
  if (loadingUserLoggedInStatus || userIsLoggedInButParticipantsStillLoading) {
    return <MainLoading />
  }

  const userCanBookSession = [
    USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION,
    USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW,
    USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_NON_USER
  ].includes(event.userSessionStatus) ||
    (event.userSessionStatus === USER_SESSION_STATUS.NOT_JOINABLE_BAD_SUBSCRIPTION_STATE && event.privateSession)
  // Only require prior booking if
  // a) user is not signed in (only relevant if this event doesn't allow signupless joining, as otherwise they'd have created a user by this point anyway), and
  // b) the session is full (which would prevent the inline book-and-join flow from working)
  const noValidParticipantFound = user === null ||
    (participantsById[user.uid] === undefined && !userCanBookSession)
  if (noValidParticipantFound) {
    return (
      <Redirect to={{
        pathname: `/session/${event.id}`,
        state: { redirectedFromSessionLobby: true }
      }} />
    )
  } else {
    return (
      <SegmentProvider eventLabel='In-Session' baseData={{ userIsHost: user.uid === event.hostId }}>
        <ConfettiProvider>
        <DailyCallWrapper event={event}>
          <ActiveSession
            event={event}
            participants={participantsById}
            badgeMembershipsByUser={badgeMembershipsByUser}
            demoSession={false}
            chatHistory={chatHistory}
            chatMessageAttachments={chatMessageAttachments}
            refetchEventData={refetchEventData}
          />
        </DailyCallWrapper>
        </ConfettiProvider>
      </SegmentProvider>
    )
  }
}

export const DailyCallWrapper = ({ event, children }) => {
  const [dailyCallObject, setDailyCallObject] = useState(null)
  const { user } = useContext(UserContext)
  const isHost = event && user ? event.hostId === user.uid : false
  const { chatOnly } = event || {}
  useEffect(() => {
    const callObjectParams = {
      videoSource: true,
      dailyConfig: {
        keepCamIndicatorLightOn: false,
        micAudioMode: isHost ? 'music' : 'speech',
        alwaysIncludeMicInPermissionPrompt: chatOnly ? false : true,
      },
    }
    if (chatOnly) {
      callObjectParams.audioSource = false
    }
    const newCallObject = DailyIframe.createCallObject(callObjectParams);
    setDailyCallObject(newCallObject);
    return () => newCallObject.destroy()
  }, []);

  return (
    <DailyProvider callObject={dailyCallObject}>
      {children}
    </DailyProvider>
  )
}

export default ParticipantApp;
import { useEffect, useState } from "react";
import { db } from './index';
import firebase from 'firebase/compat/app'
import { usePrevious } from "../utils";

const parseDocumentIntoObject = document => ({...document.data(), id: document.id})

const filterFields = (document, fields) => Object.fromEntries(fields.map(field => [field, document[field]]))

const parseDataFromQuery = (querySnapshot, fields) => {
  const dataIsSingleDocument = querySnapshot.data !== undefined
  if (dataIsSingleDocument) {
    if (!querySnapshot.exists) {
      return null
    }
    
    const queriedData = parseDocumentIntoObject(querySnapshot)
    if (fields !== undefined) {
      return filterFields(queriedData, fields)
    } else {
      return queriedData
    }
  } else {
    const queriedData = querySnapshot.docs.map(document => parseDocumentIntoObject(document))
    if (fields !== undefined) {
      return queriedData.map((document => filterFields(document, fields)))
    } else {
      return queriedData
    }
  }
}

const createQuery = (collection, id, functionalQuery) => {
  let query = null
  if (functionalQuery !== undefined) {
    try {
      query = functionalQuery(db)
    } catch (error) {
      // console.log("Error while constructing query from functional query: ")
      // console.log(functionalQuery.toString())
      // console.log(error)
    }
  } else {
    query = db.collection(collection)
    if (id !== undefined) {
      try {
        query = query.doc(id)
      } catch (error) {
        console.log("Error while constructing query using .doc(); likely due to id passed being null")
      }
    }
  }
  return query
}

// "Memoized" is maybe not quite the right term -- but the idea is queryId will only change if the queries are meaningfully different
// so we can use that as a diff key
// Theoretically I guess eventually this will overflow (except it's Javascript so I think that means it becomes infinity),
// which isn't the most elegant. But I'm inclined to not care
const useMemoizedQuery = (query) => {
  const [queryId, setQueryId] = useState(0)
  const previousQuery = usePrevious(query)
  const queriesAreEquivalent = previousQuery !== undefined && (
    (previousQuery !== null && query !== null && previousQuery.isEqual(query)) ||
    (previousQuery === null && query === null))
  useEffect(() => {
    if (!queriesAreEquivalent) {
      setQueryId(queryId => queryId + 1)
    }
  })
  return [query, queryId]
}

const useDataFromFirestore = ({ collection, id, fields, functionalQuery, transform = data => data, subscribe }) => {
  const queryIsForSingleDocument = id !== undefined
  const defaultData = queryIsForSingleDocument ? null : []

  const defaultStatus = { data: defaultData, loading: true }
  const [queryStatus, setQueryStatus] = useState({ ...defaultStatus })

  const [query, queryId] = useMemoizedQuery(createQuery(collection, id, functionalQuery))

  useEffect(() => {
    setQueryStatus({ ...queryStatus, loading: true })
    if (query !== null) {
      if (subscribe) {
        const unsubscribe = query.onSnapshot((querySnapshot) => {
          setQueryStatus({ data: parseDataFromQuery(querySnapshot, fields), loading: false })
        });
        return unsubscribe
      } else {
        (async () => {
          setQueryStatus({ data: parseDataFromQuery((await query.get()), fields), loading: false })
        })();
      }
    } else {
      setQueryStatus({ ...defaultStatus, loading: false })
    }
  }, [collection, id, JSON.stringify(fields), queryId, subscribe])

  const { data, loading } = queryStatus

  const transformedData = data !== null ? transform(data) : data

  return [transformedData, loading]
}

/**
 * A hook that subscribes to updates from Firestore on documents matching a query, and returns new results without needing to make a new query.
 * @param {{ collection?: string, id?: string, fields?: Array<string>, functionalQuery?: function, transform?: function }} queryParameters
 * @param collection The Firestore collection to query from. Used for simple queries without additional filtering clauses such as .where
 * @param id Specific document id to search for. Used together with collection for simple queries
 * @param fields Which properties of the queried documents to include
 * @param functionalQuery A function which is passed the firestore db object and which should return a valid Firestore query. Used for more complex queries in place of collection & id
 * @param transform An optional function run after results are retrieved, to transform them before returning from the hook
 * @returns {[Array<any> | null | any, boolean]}
 */
export const useFirestoreSubscribe = ({collection, id, fields, functionalQuery, transform}) => {
  return useDataFromFirestore({collection, id, fields, functionalQuery, transform, subscribe: true})
}

/**
 * A hook that retrieves data from Firestore once, and only requests new data if the query parameters change.
 * @param {{ collection?: string, id?: string, fields?: Array<string>, functionalQuery?: function, transform?: function }} queryParameters
 * @param collection The Firestore collection to query from. Used for simple queries without additional filtering clauses such as .where
 * @param id Specific document id to search for. Used together with collection for simple queries
 * @param fields Which properties of the queried documents to include
 * @param functionalQuery A function which is passed the firestore db object and which should return a valid Firestore query. Used for more complex queries in place of collection & id
 * @param transform An optional function run after results are retrieved, to transform them before returning from the hook
 * @returns {[Array<any> | null | any, boolean]}
 */
export const useFirestoreQuery = ({collection, id, fields, functionalQuery, transform}) => {
  return useDataFromFirestore({collection, id, fields, functionalQuery, transform, subscribe: false})
}


// For use with firestore documentId 'in' array queries -- is safe to use even when querying for >10 ids (or any other matching field)
// For context, if you try to use an 'in' filter with one of the simple query methods with >10 values, you get this fun error:
// FirebaseError: Invalid Query. 'in' filters support a maximum of 10 elements in the value array.
export const useCompoundFirestoreSubscribe = (collection, filterValues, fields, filterField = null) => {
  const MAX_FILTER_VALUES_PER_QUERY = 10
  const bucketCount = Math.ceil(filterValues.length / MAX_FILTER_VALUES_PER_QUERY)
  
  const [data, setData] = useState({})

  const addIdToFieldsForSorting = !fields.includes('id')
  if (addIdToFieldsForSorting) {
    fields.push('id')
  }

  useEffect(() => {
    setData({})
    // Breaks the requested filterValues up into buckets of 10, as that's the max Firestore will allow in one query, and makes a separate query for each bucket
    // Essentially, this is paginating but requesting and returning every page at once
    // In light of which, why did I call these buckets and not pages? I'm...not sure.
    const buckets = []
    for (let bucketIndex = 0; bucketIndex < bucketCount; bucketIndex++) {
      buckets.push(filterValues.slice(bucketIndex * MAX_FILTER_VALUES_PER_QUERY, (bucketIndex + 1) * MAX_FILTER_VALUES_PER_QUERY))
    }

    const queries = buckets.map(bucketFilterValues =>
      createQuery(null, null, db =>
        db.collection(collection)
          .where(filterField || firebase.firestore.FieldPath.documentId(), 'in', bucketFilterValues))
    )

    const unsubscribeHandles = queries.map((query, bucketIndex) => query.onSnapshot((querySnapshot) => {
      // Stores data from each 'page' independently, so they don't overwrite each other when one gets an update
      setData(existingBuckets => 
        ({...existingBuckets, [bucketIndex]: sortBucketResults(parseDataFromQuery(querySnapshot, fields), buckets[bucketIndex])})
      )
    }))

    return () => unsubscribeHandles.forEach(unsubscribe => unsubscribe())
  }, [collection, JSON.stringify(filterValues), JSON.stringify(fields), filterField])

  // Side effect of this implementation: as the data loads, it will gradually fill in each bucket (admittedly, 
  // probably with an imperceptible period of time in between each) and that intermediate data will become available
  // to the consuming function in what I suspect might be a non-guaranteed order. Could potentially be fixed along with
  // adding a loading state, but it likely doesn't really matter
  const flattenedData = Object.values(data).reduce((mergedBuckets, bucket) => mergedBuckets.concat(bucket), [])
  const loading = Object.values(data).length < bucketCount
  if (addIdToFieldsForSorting) {
    flattenedData.forEach(document => delete document.id)
  }

  return [flattenedData, loading]
}

const sortBucketResults = (data, originalIdsOrder) => {
  const sortedData = [...data]
  sortedData.sort((doc1, doc2) => originalIdsOrder.indexOf(doc1.id) - originalIdsOrder.indexOf(doc2.id))
  return sortedData
}
import { useContext } from 'react';
import firebase from 'firebase/compat/app';
import { useUpgradeUnauthedUser } from '../fetch/endpoints';
import { translateAuthErrorToToast } from '../components/utils/auth';
import { UserContext } from '../UserProvider';
import { toastError } from '../components/utils/toaster';
import { signInWithCredentialAndReload } from '../firebase';

const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider()
GOOGLE_PROVIDER.setCustomParameters({
  prompt: 'select_account',
})

export const useFinishSigningUp = ({ onSuccess = () => { }, onError = () => { } }) => {
  const { user, authUser } = useContext(UserContext);

  const { performFetch: upgradeUnauthedUser } = useUpgradeUnauthedUser()

  const upgradeUser = async (userId, email) => {
    const { success, error, result } = await upgradeUnauthedUser({ userId, email })
    if (success) {
      onSuccess({ user: result.upgradedUser, authType: 'upgradeUnauthed' });
    } else {
      toastError(error)
      onError(error)
    }
  }

  const upgradeWithGoogle = () => {
    authUser.linkWithPopup(GOOGLE_PROVIDER).then((e) => {
      console.log(e)
      upgradeUser(user.uid, authUser.email)
    }).catch((e) => {
      if (e.code === "auth/popup-closed-by-user") {
        // no-op, write this way for clarity. don't close the modal if the user
        // runs into this error.
      } else if (e.code) {
        translateAuthErrorToToast({ errorCode: e.code })
      } else {
        onError(e)
      }
    });
  }

  const upgradeWithEmail = async ({ email, password }) => {
    // update auth method
    let credential = firebase.auth.EmailAuthProvider.credential(email, password);
    await authUser.linkWithCredential(credential)
      .then((usercred) => {
        var user = usercred.user;
        console.log("Anonymous account successfully upgraded", user);
        upgradeUser(user.uid, email)
      }).catch((error) => {
        translateAuthErrorToToast({ errorCode: error.code })
        console.log("Error upgrading anonymous account via email login", error);
        onError(error);
      });
    await signInWithCredentialAndReload(credential)
  }

  return { upgradeWithEmail, upgradeWithGoogle }
}
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Spinner } from 'react-bootstrap';
import { HostBadge, HostBadgePill } from '../../../components/hosts/host-badge';
import { SegmentProvider, useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import { useContext, useMemo, useState } from 'react';
import EventContext from '../../../EventContext';
import { getUserIsHost, sortBy, getOrdinalNumber } from '../../../../functions/shared/helpers';
import { UserContext, useGetDisplayName } from '../../../UserProvider';
import { SubscriptionStatus } from '../../../../functions/shared/constants';
import { FC_DARK_BLUE, FC_RED, FC_GREEN, TEXT_60_PERCENT, NEUTRAL_70, FC_LIGHT_BLUE, NEUTRAL_30, NEUTRAL_50 } from '../../../emotionVariables';
import { BadgeEmojiIcon } from '../../../BadgeEmojiIcon';
import { CheckboxButton, Text, TEXT_STYLES } from '../../../components/flowComponents';
import { useAudioTrack, useParticipantIds, useVideoTrack } from '@daily-co/daily-react';
import { AudioOff, AudioOn } from '../Tile/TileIcons';
import Icon, { TYPE_LINKEDIN_CIRCLE, TYPE_TWITTER_CIRCLE, TYPE_CAMERA_OFF, TYPE_CAMERA_ON, TYPE_LINK } from '../Icon/Icon';
import { useAutomaticallyFetch } from '../../../fetch/helpers';
import { useAttendeesInfoForParticipantUserIds } from '../../../fetch/endpoints';
import { TooltipWrapper } from '../../../components/TooltipWrapper';

const milestones = new Set([1, 5, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]);

export const Attendees = () => {
  const { participants } = useContext(EventContext);
  const participantIds = useMemo(() => Object.keys(participants), [participants])
  const participantCount = useMemo(() => Object.keys(participants).length, [participants]);

  const { result: users, loading: attendeesLoading } = useAutomaticallyFetch(
    useAttendeesInfoForParticipantUserIds,
    { participantUserIds: participantIds },
    {
      dependencies: [participantCount],
      transform: data => data.filter(attendee => participants[attendee.id] !== undefined)
    }
  );


  if (!users || attendeesLoading) {
    return <Spinner animation="grow" variant="primary" />;
  }

  return (
    <AttendeesList users={users} participants={participants} />
  );
}
const AttendeesList = ({ users, participants }) => {
  const { user } = useContext(UserContext)
  const { event, callObject } = useContext(EventContext)
  const { hostId } = event
  const viewerIsHost = user.uid === hostId
  const viewerIsTrialing = user.subscriptionStatus === SubscriptionStatus.TRIALING_NO_CARD

  // does this look silly? yes. did I fail to figure out a better way to make it readable than this? yep.
  // Sort order: host always first, then all attendees who have joined ordered by session count descending,
  // then alphabetical by name, followed by all attendees who haven't joined yet ordered the same way
  const sortedAttendees =
    sortBy(
      sortBy(
        sortBy(
          sortBy(
            users,
            "displayName"
          ),
          "sessions"
        ),
        attendee => participants[attendee.id].joined !== undefined, true
      ),
      attendee => attendee.id === hostId, true
    )

  const sendSegmentEvent = useSendSegmentEvent()
  const muteAllClicked = () => {
    sendSegmentEvent("Attendees Mute All Clicked")

    let updateList = {};
    for (let id in callObject.participants()) {
      if (id === "local") {
        continue;
      }
      updateList[id] = { setAudio: false };
    }
    callObject.updateParticipants(updateList);
    callObject.setLocalAudio(false);
  }

  const [showDetails, setShowDetails] = useState(true)

  return (
    <div>
      <div css={css`
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      `}>
        <div css={css`
          display: flex;
          align-items: center;
        `}>
          <CheckboxButton selected={showDetails} setSelected={setShowDetails} />
          <Text style={TEXT_STYLES.OVERLINE}>DETAILS</Text>
        </div>
        {viewerIsHost &&
          <div onClick={muteAllClicked} css={css`
            display: flex;
            align-items: center;
            border-radius: 16px;
            padding: 4px 8px 4px 12px;
            cursor: pointer;
            border: 1px solid ${NEUTRAL_50};
            &:hover {
              background-color: ${NEUTRAL_30};
            }
          `}>
            <Text style={TEXT_STYLES.OVERLINE}>MUTE ALL</Text>
            <AudioOff />
          </div>
        }
      </div>
      <div css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}>
        {sortedAttendees.map((guest) =>
          <Attendee
            key={guest.id}
            guest={guest}
            attendeeIsHostingSession={guest.id === hostId}
            viewerIsAttendee={guest.id === user.uid}
            viewerIsHost={viewerIsHost}
            joined={participants[guest.id].joined !== undefined}
            viewerIsTrialing={viewerIsTrialing}
            goals={participants[guest.id].goals}
            showDetails={showDetails}
          />
        )}
      </div>
    </div>
  )
}

const Attendee = ({ guest, attendeeIsHostingSession, viewerIsAttendee, viewerIsHost, joined, viewerIsTrialing, goals, showDetails }) => {
  const { id: guestId, sessions, isTrialing, displayName, pronunciation, pronouns, location, linkedin, twitter, linkUrl, linkTitle, inProgressCertifications } = guest
  const [ showAllBadges, setShowAllBadges ] = useState(false)
  const userDisplayName = useGetDisplayName(guest)
  const sendSegmentEvent = useSendSegmentEvent()
  const { badgeMembershipsByUser, callObject } = useContext(EventContext)
  const isFirstSession = sessions === 0
  const sessionNumber = sessions + 1
  const isMilestone = milestones.has(sessionNumber)
  const attendeeIsHost = getUserIsHost(guest)

  const badgeMembership = badgeMembershipsByUser[guestId] ?? []
  
  const trackOffStates = ["off", "blocked", "interrupted"]

  const [dailyParticipantId] = useParticipantIds({ filter: ({ user_id }) => user_id === guestId });
  const audioTrack = useAudioTrack(dailyParticipantId)
  const muted = trackOffStates.includes(audioTrack.state)
  const videoTrack = useVideoTrack(dailyParticipantId)
  const videoOff = trackOffStates.includes(videoTrack.state)
  const viewerCanMute = !muted && viewerIsHost
  const viewerCanTurnOffVideo = !videoOff && viewerIsHost

  const onAttendeeMousedOver = () => {
    sendSegmentEvent("Attendee Moused over", {
      isOwnAttendee: viewerIsAttendee,
      isHost: attendeeIsHostingSession,
      isFirstSession,
      isMilestone,
      isTrialing,
    })
  }

  const onGuestBadgeClicked = () => {
    if (!viewerIsTrialing) return;
    sendSegmentEvent("Guest Badge Clicked", {
      isFirstSession,
      isOwnBadge: viewerIsAttendee,
    })
    window.open("/plans/")
  }

  const linkedinIconLinkClicked = () => {
    sendSegmentEvent("Linkedin Icon Link Clicked", { linkedin, from: "attendeesList" })
  }

  const twitterIconLinkClicked = () => {
    sendSegmentEvent("Twitter Icon Link Clicked", { twitter, from: "attendeesList" })
  }

  const externalLinkClicked = () => {
    sendSegmentEvent("External Link Clicked", {
      linkUrl,
      linkTitle,
      from: "attendeesList",
    })
  }

  const muteButtonClicked = () => {
    if (!viewerCanMute) return;
    sendSegmentEvent("Attendee Muted By Host", {
      isOwnAttendee: viewerIsAttendee,
      mutedUserId: guestId,
      isFirstSession,
      isMilestone,
      isTrialing,
    })
    if (viewerCanMute) {
      callObject.updateParticipant(dailyParticipantId, { setAudio: false })
    }
  }

  const turnVideoOffButtonClicked = () => {
    if (!viewerCanTurnOffVideo) return;
    sendSegmentEvent("Host turned off attendee's video", {
      isOwnAttendee: viewerIsAttendee,
      videoTurnedOffUserId: guestId,
      isFirstSession,
      isMilestone,
      isTrialing,
    })
    if (viewerCanTurnOffVideo) {
      callObject.updateParticipant(dailyParticipantId, { setVideo: false })
    }
  }

  // If the viewer isn't the host and the attendee hasn't joined yet, hide them
  if (!viewerIsHost && !attendeeIsHostingSession && !joined) return "";

  const attendeeBadgeStyle = css`
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    border-radius: 8px;
    white-space: nowrap;
    color: #ffffff;

    padding: 2px 12px;
    margin: 0 4px;
  `

  const milestoneStyle = css`
    background: ${FC_RED};
  `

  const guestStyle = css`
    background: ${FC_GREEN};
  `
  const MAX_BADGES_TO_SHOW = 6

  return (
    <div onMouseEnter={onAttendeeMousedOver} css={css`
      border-radius: 8px;

      background: #F7F7F7;
      padding: 8px;
      position: relative;
    `}
    >
      <div css={css`opacity: ${dailyParticipantId ? "1.0" : "0.6"}; display: flex; flex-direction: column; gap: 8px;`}>
        <div css={css`display: flex; align-items: center; gap: 12px;`}>
          {attendeeIsHost &&
            (attendeeIsHostingSession ?
              <HostBadgePill /> :
              <HostBadge user={guest} />
            )
          }
          <Text customCss={css`font-weight: bold;`}>{`${userDisplayName}${viewerIsAttendee ? " (you)" : ""}`}</Text>
        </div>
        {showDetails &&
          <>
            <div css={css`display: flex; flex-wrap: wrap; gap: 8px;`}>
              {viewerIsHost && <span css={css`color: ${TEXT_60_PERCENT};`}>{getOrdinalNumber(sessions + 1)}</span>}
              {[pronunciation, pronouns, location].map(property => property !== "" &&
                <Text key={property} style={TEXT_STYLES.BODY_2} customCss={css`color: ${NEUTRAL_70};`}>{property}</Text>
              )}
            </div>
            {inProgressCertifications !== "" && inProgressCertifications !== undefined && <div>
              🔜📜 {inProgressCertifications}
            </div>}
            <div css={css`display: flex; align-items: center;`}>
              <div css={css`margin-right: 8px;`}>
                <div css={css`display: flex; gap: 4px;`}>
                  {linkedin !== "" && linkedin !== undefined &&
                    <a onClick={linkedinIconLinkClicked} href={linkedin} target="_blank" rel="noreferrer">
                      <Icon css={css`fill: white; &:hover { fill: ${FC_LIGHT_BLUE}; }`} type={TYPE_LINKEDIN_CIRCLE} />
                    </a>
                  }
                  {twitter !== "" && twitter !== undefined &&
                    <a onClick={twitterIconLinkClicked} href={twitter} target="_blank" rel="noreferrer">
                      <Icon css={css`fill: white; &:hover { fill: ${FC_LIGHT_BLUE}; }`} type={TYPE_TWITTER_CIRCLE} />
                    </a>
                  }
                  {linkUrl !== "" && linkUrl !== undefined &&
                  linkTitle !== "" && linkTitle !== undefined &&
                    <TooltipWrapper TooltipContents={`${linkTitle}: ${linkUrl}`} useFloatingArgs={{ placement: 'right' }}>
                      <a onClick={externalLinkClicked} href={linkUrl} target="_blank" rel="noreferrer" title={linkTitle}>
                        <span css={css`
                          height: 24px;
                          padding: 4px;
                          line-height: 32px;
                          &:hover { background: ${FC_LIGHT_BLUE}; }
                          border-radius: ${linkTitle === null ? '50%' : '10px'};
                          background: white;
                          border-radius: 50%;
                          color: #757575;
                          cursor: pointer;
                        `}>
                        <Icon
                          css={css`
                            width: 16px;
                            height: 16px;
                            fill: white;
                            &:hover { fill: ${FC_LIGHT_BLUE}; }
                          `}
                          type={TYPE_LINK}
                        />
                        </span>
                      </a>
                    </TooltipWrapper>
                  }
                </div>
              </div>
              <SegmentProvider baseData={{ participantTotalBadges: badgeMembership.length, participantUserId: guestId, participantDisplayName: displayName }}>
                {badgeMembership.length > 0 &&  <div css={css`
                  display: flex;
                  flex-wrap: wrap; // Allow badges to wrap to multiple lines
                  background-color: white;
                  border-radius: 16px;
                  padding: 0px 16px;
                  align-items: center;
                `}>
                  {showAllBadges
                    ? badgeMembership.map((badge, index) => <BadgeEmojiIcon key={index} badge={badge} />)
                    : badgeMembership.slice(0, MAX_BADGES_TO_SHOW).map((badge, index) => <BadgeEmojiIcon key={index} badge={badge} />)}

                  {badgeMembership.length > MAX_BADGES_TO_SHOW && (
                    <span
                      css={css`
                        cursor: pointer;
                        color: ${FC_DARK_BLUE}
                      `}
                      onClick={() => setShowAllBadges(!showAllBadges)}
                    >
                      {showAllBadges ? 'Collapse' : `+${badgeMembership.length - MAX_BADGES_TO_SHOW}`}
                    </span>
                  )}
                </div>}
              </SegmentProvider>
            </div>
          </>
        }
        <div css={css`display: flex;`}>
          {(isFirstSession || isMilestone) &&
            <span css={css`${attendeeBadgeStyle} ${milestoneStyle}`}>
              {sessions === 0 ?
                "First Flow" :
                getOrdinalNumber(sessionNumber)
              }
            </span>
          }
          {isTrialing &&
            <span css={css`
              ${attendeeBadgeStyle}
              ${guestStyle}
              ${viewerIsTrialing ? "cursor: pointer;" : ""}
            `} onClick={onGuestBadgeClicked}>
              Guest
            </span>
          }
        </div>
        {showDetails &&
          <AttendeeGoalsList goals={goals} />
        }
      </div>
      <div onClick={muteButtonClicked} css={css`
        position: absolute;
        top: 8px;
        right: 8px;

        background: rgba(255, 255, 255, 0.87);
        border-radius: 8px;
        border: 1px solid transparent;

        ${viewerCanMute && css`
          cursor: pointer;
          border-color: ${NEUTRAL_50};
          &:hover {
            background-color: ${NEUTRAL_30};
            /* hacky nonsense yay */
            ::before {
              content: "Mute";
              font-size: 12px;
            }
            padding-left: 4px;
          }
        `}
      `}>
        {muted ? <AudioOff /> : <AudioOn />}
      </div>
      <div onClick={turnVideoOffButtonClicked} css={css`
        position: absolute;
        top: 8px;
        right: 40px;

        background: rgba(255, 255, 255, 0.87);
        border-radius: 8px;
        border: 1px solid transparent;

        ${viewerCanTurnOffVideo && css`
          cursor: pointer;
          border-color: ${NEUTRAL_50};
          &:hover {
            background-color: ${NEUTRAL_30};
            /* hacky nonsense yay */
            ::before {
              content: "Turn video off";
              font-size: 12px;
            }
            padding-left: 4px;
          }
        `}
      `}>
        <Icon type={videoOff ? TYPE_CAMERA_OFF : TYPE_CAMERA_ON} css={css` width: 24px; height: 24px; `}/>
      </div>
    </div>
  )
}

const AttendeeGoalsList = ({ goals }) => {
  return (
    <div>
      <Text style={TEXT_STYLES.OVERLINE}>TASKS</Text>
      {goals.filter(goal => goal.text.length > 0).map((goal, index) => <Text key={index} style={TEXT_STYLES.BODY_2}>{`${goal.completedAt !== null ? '✅' : '⬜️️'} ${goal.text}`}</Text>)}
    </div>
  )
}
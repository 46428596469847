import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';


const firebaseConfig = {
  "projectId": process.env.REACT_APP_PROJECT_ID,
  "appId": process.env.REACT_APP_APP_ID,
  "storageBucket": process.env.REACT_APP_STORAGE_BUCKET,
  "locationId": process.env.REACT_APP_LOCATION_ID,
  "apiKey": process.env.REACT_APP_API_KEY,
  "authDomain": process.env.REACT_APP_AUTH_DOMAIN,
  "messagingSenderId": process.env.REACT_APP_MESSAGING_SENDER_ID,
  "measurementId": process.env.REACT_APP_MEASUREMENT_ID
}
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth()
const functionsHost = process.env.REACT_APP_FUNCTIONS_HOST
const dailyHost = process.env.REACT_APP_DAILY_HOST
const baseURL = process.env.REACT_APP_BASE_URL

if (window.location.hostname === 'localhost') {
    console.log("testing locally -- hitting local functions and firestore emulators");
    // auth.useEmulator('http://localhost:9099/');
    firebase.functions().useEmulator('localhost', 5001);
    db.settings({
        host: 'localhost:9001',
        ssl: false
    });
}

// No-op for the actual Authentication service, only used for local authentication emulator
// Fixes auth token not getting updated after upgrading from unauthed user to one with
// a credential for the local Authentication emulator
// https://github.com/invertase/react-native-firebase/issues/6579
const signInWithCredentialAndReload = async (credential) => {
  if (window.location.hostname === 'localhost') {
    await auth.signInWithCredential(credential)
    await auth.currentUser.reload();
  }
}
export { auth, db, functionsHost, dailyHost, baseURL, signInWithCredentialAndReload }



/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { Button } from 'react-bootstrap';
import 'firebase/compat/firestore';
import { db } from './firebase'
import { useCompoundFirestoreSubscribe, useFirestoreSubscribe } from './firebase/helpers';
import { arrObjectsToObject } from '../functions/shared/helpers';

export const getUser = (userId) => {
    return db.collection('users')
    .doc(userId)
    .get()
    .then(userDoc => {
        if (userDoc.exists) {
            let user = userDoc.data();
            user.uid = userId;
            return user;
        }
        throw Error("No such user.")
    }).catch((err) => {
        console.error(err);
    })
}

export const EditIcon = ({ onClick, className, buttonCss, iconCss }) => {
    return (
        <Button variant="button" className={className} onClick={onClick} css={css`${buttonCss}`}>
            <svg css={css`${iconCss}`} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6221 2.47715L15.5211 1.37614C14.6113 0.465642 13.1293 0.465642 12.2189 1.37614L2.31359 11.283C2.25134 11.3452 2.20559 11.4225 2.18159 11.5072L0.714599 16.644C0.662849 16.8255 0.713849 17.0197 0.846599 17.1532C0.945598 17.2522 1.07759 17.3055 1.21411 17.3055C1.26211 17.3055 1.31011 17.2987 1.35661 17.2852L6.49337 15.8175C6.57812 15.7935 6.65613 15.7477 6.71838 15.6855L16.6221 5.7802C17.0639 5.3392 17.3061 4.7527 17.3061 4.1287C17.3061 3.5047 17.0639 2.91819 16.6221 2.47719L16.6221 2.47715ZM6.07706 14.8573L1.96788 16.0311L3.14238 11.9226L11.1194 3.94562L14.0549 6.88111L6.07706 14.8573ZM15.8886 5.04649L14.7876 6.1475L11.8522 3.21201L12.9532 2.11101C13.4587 1.60551 14.2822 1.60626 14.7884 2.11101L15.8894 3.21201C16.1347 3.4565 16.2697 3.78352 16.2697 4.12925C16.2689 4.47501 16.1332 4.8005 15.8887 5.04649H15.8886Z" fill="black" fillOpacity="0.6" />
            </svg>
        </Button>
    );
}

export const eventWithParsedDates = event => ({
    ...event,
    start: event.start.toDate(),
    date: dayjs(event.start.toDate()).format('YYYY-MM-DD'),
    ...(event.end ? { end: event.end.toDate() } : {})
})

// This could maybe be abstracted slightly further to work as a generic join function
// Should probably solve the obnoxiousness of Firebase getting mad at the eventIds being an empty array first though
// ("FirebaseError: Invalid Query. A non-empty array is required for 'in' filters.")
export const useEventsWithParticipantData = (participantsSubscribe, eventFields, participantFieldsToInclude) => {
    const [participants] = useFirestoreSubscribe(participantsSubscribe)
    const eventIds = participants.map(participant => participant.eventId)
    let [events, eventsLoading] = useCompoundFirestoreSubscribe('events', eventIds, eventFields)

    if (participantFieldsToInclude !== undefined && participants.length === events.length) {
        const participantsByEventId = arrObjectsToObject(participants, "eventId")
        events.forEach((event, index) => {
            // check to make sure useCompoundFirestoreSubscribe() has returned up to date values to match with our original useFirestoreSubscribe()
            // (or rather, that useFirestoreSubscribe() hasn't 'scrolled' past old data that the useCompoundFirestoreSubscribe() events were relying on joining from)
            if (participantsByEventId[event.id] !== undefined) {
                participantFieldsToInclude.forEach(field => event[field] = participantsByEventId[event.id][field])
            } else {
                events[index] = null
            }
        })
        // filter out events that no longer have data to join off of
        events = events.filter(event => event !== null)
    }

    return [events, eventsLoading]
}
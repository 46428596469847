/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import dayjs from 'dayjs'

import React, {
  forwardRef,
  memo,
  useContext,
  useMemo,
  useState,
} from "react";
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useWithVisibility } from '../../utils';
import { EMPTY_HOST_ID, getEffectiveMaxAttendees } from '../SessionUtils';
import { HostInterestSession } from '../../components/hosts/host-interest-session';
import { HostStatus, USER_SESSION_STATUS, SubscriptionStatus, FAVORITE_HOSTS_FILTER } from '../../../functions/shared/constants';
import { NoSessionsMessage } from './NoSessionsMessage';
import { BUTTON_STYLES, FlowButton, LoadingIndicator, Text, TEXT_STYLES, UnstyledLink } from '../../components/flowComponents';
import { UserContext } from '../../UserProvider';
import { useMediaQuery } from 'react-responsive';
import { SegmentProvider, useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { getSessionIsJoinable, getUserCanHostSessions, groupObjectsByProperty, sortBy } from '../../../functions/shared/helpers';
import { FC_GREEN, FC_LIGHT_GREEN, NEUTRAL_20, NEUTRAL_40, TEXT_60_PERCENT } from '../../emotionVariables';
import { EventCard, HostAvatar } from '../EventCard';
import { ScheduleContext, SCHEDULE_VIEW_MODES } from './Schedule';
import { fillScheduleWithHostTBDCreatableSessions } from './fillScheduleWithHostTBDCreatableSessions';
import { BookingButtonWrapper } from '../../components/BookingButtonWrapper';
import { getOnlyShowChatOnly, getOnlyShowPomodoro } from './useThemeFilters';
import { getShow60Minutes } from './useDurationFilters';

export const EventsStateContext = React.createContext(null);
export const EventsList = ({
  user,
  events,
  initialLoading,
  additionalLoading,
  selectedHost,
  groupFilter,
  invitedBy,
  guestPass,
  setDayVisibility,
  numCurrentlyWorking,
  numCurrentSessions,
  canCreateSessions,
  activeThemeFilters,
  activeDurationFilters,
}) => {
  const mobileView = useMediaQuery({query: '(max-width:767px)'})
  const isHostQualified = Boolean(user && user.hostStatus === HostStatus.QUALIFIED)
  const userCanHostSessions =
    (user !== null && getUserCanHostSessions(user)) || // this line should technically be unnecessary now, but will make things less buggy if receiving out of date cloud function responses
    canCreateSessions

  const visibleEvents = events !== null ?
    events.filter(event =>
      (selectedHost === undefined || selectedHost === FAVORITE_HOSTS_FILTER && event.isFavoriteHost || selectedHost === event.hostUser.displayName) &&
      (groupFilter === null || groupFilter === 'all' || event.visibility === groupFilter)
    )
  : []

  const eventsValidForRescheduling = events !== null ? events.filter(event => getSessionIsJoinable(event.userSessionStatus)) : []

  const eventsToDisplayByDay = visibleEvents.filter(event => event.scheduleSection === "full" || event.scheduleSection === "recommended")
  const recommendedSessions = visibleEvents.filter(event => event.scheduleSection === "recommended")

  const sessionsByDay = groupObjectsByProperty(eventsToDisplayByDay, event => dayjs(event.start).format('YYYY-MM-DD'))
  Object.keys(sessionsByDay).forEach(date => sessionsByDay[date] = { date, sessions: sessionsByDay[date] })
  const daysOfEvents = sortBy(Object.values(sessionsByDay), ({ date }) => new Date(date).getTime())

  return (
    <Container>
      <div css={css`
        padding: 0rem 1.5rem;
        margin-top: 1rem;
        margin-bottom: 8px;

        display: flex;
        @media (max-width: 899px) { flex-direction: column; }
        justify-content: space-between;
        align-items: center;
        gap: 24px;
      `}>
        {numCurrentlyWorking !== null && numCurrentlyWorking > 0 && <CurrentlyWorkingCountIndicator numCurrentlyWorking={numCurrentlyWorking} numCurrentSessions={numCurrentSessions} />}
        {!mobileView && <SessionButtonBonanza userCanHostSessions={userCanHostSessions} />}
      </div>
      <TimezoneIndicator />
      {events.length > 0 ?
        <EventsStateContext.Provider value={{
          user,
          isHostQualified,
          invitedBy,
          guestPass,
          eventsValidForRescheduling,
        }}>
          {recommendedSessions.length > 0 && <EventsSection sessions={recommendedSessions} title={"Recommended"} displayRecommendedSessionType />}
          {daysOfEvents.map(({ date, sessions }, index) =>
            <DayOfEvents
              key={date}
              date={date}
              sessions={sessions}
              setDayVisibility={(visible) => {
                setDayVisibility(index, visible)
              }}
              selectedHost={selectedHost}
              activeThemeFilters={activeThemeFilters}
              activeDurationFilters={activeDurationFilters}
            />
          )}

          {additionalLoading && <SessionLoading />}
        </EventsStateContext.Provider> :
        (initialLoading || additionalLoading ? <SessionLoading /> : <NoSessionsMessage />)
      }
      <TimezoneIndicator />
    </Container>
  )
}

const quoteLoadingMessages = [
  {
      "quote": "I was taught that the way of progress was neither swift nor easy.",
      "author": "Marie Curie"
  },
  {
      "quote": "I have learned over the years that when one's mind is made up, this diminishes fear; knowing what must be done does away with fear.",
      "author": "Rosa Parks"
  },
  {
      "quote": "It does not matter how slowly you go as long as you do not stop.",
      "author": "Confucius"
  },
  {
      "quote": "All great achievements require time.",
      "author": "Maya Angelou"
  },
  {
      "quote": "It's not that I'm so smart, it's just that I stay with problems longer.",
      "author": "Albert Einstein"
  },
  {
      "quote": "Every great dream begins with a dreamer. Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world.",
      "author": "Harriet Tubman"
  },
  {
      "quote": "Do what you can, with what you have, where you are.",
      "author": "Theodore Roosevelt"
  },
  {
      "quote": "The most difficult thing is the decision to act, the rest is merely tenacity.",
      "author": "Amelia Earhart"
  },
  {
      "quote": "A journey of a thousand miles begins with a single step.",
      "author": "Lao Tzu"
  },
  {
      "quote": "I long to accomplish a great and noble task, but it is my chief duty to accomplish small tasks as if they were great and noble.",
      "author": "Helen Keller"
  },
  {
      "quote": "Never doubt that a small group of thoughtful, committed citizens can change the world; indeed, it's the only thing that ever has.",
      "author": "Margaret Mead"
  },
  {
      "quote": "It always seems impossible until it's done.",
      "author": "Nelson Mandela"
  },
  {
      "quote": "How wonderful it is that nobody need wait a single moment before starting to improve the world.",
      "author": "Anne Frank"
  },
  {
      "quote": "The people who are crazy enough to think they can change the world are the ones who do.",
      "author": "Steve Jobs"
  },
  {
      "quote": "You must learn to be still in the midst of activity and to be vibrantly alive in repose.",
      "author": "Indira Gandhi"
  },
  {
      "quote": "If you can't fly then run, if you can't run then walk, if you can't walk then crawl, but whatever you do you have to keep moving forward.",
      "author": "Martin Luther King Jr."
  },
  {
      "quote": "One child, one teacher, one book, and one pen can change the world.",
      "author": "Malala Yousafzai"
  },
  {
      "quote": "Coming together is a beginning; keeping together is progress; working together is success.",
      "author": "Henry Ford"
  },
  {
    "quote": "You may encounter many defeats, but you must not be defeated. In fact, it may be necessary to encounter the defeats, so you can know who you are, what you can rise from, how you can still come out of it.",
    "author": "Maya Angelou"
  },
  {
    "quote": "Courage doesn't always roar. Sometimes courage is the little voice at the end of the day that says I'll try again tomorrow.",
    "author": "Mary Anne Radmacher"
  },
  {
    "quote": "If you fell down yesterday, stand up today.",
    "author": "H.G. Wells"
  },
  {
    "quote": "Dripping water hollows out stone, not through force but through persistence.",
    "author": "Ovid"
  },
  {
    "quote": "Courage is not having the strength to go on; it is going on when you don't have the strength.",
    "author": "Theodore Roosevelt",
  },
];

const SessionLoading = () => {
  const loadingMessage = useMemo(() => quoteLoadingMessages[Math.floor(Math.random() * quoteLoadingMessages.length)], [])
  const fadeAnimation = keyframes({
    '0%, 100%': css`opacity: 0.9;`,
    '50%': css`opacity: 0.7;`
  })

  const { quote, author } = loadingMessage
  return (
    <div
      css={css`
        justify-content: center;
        height: 100vh;
        padding: 50px;
        margin: 20px;
        animation:  ${fadeAnimation} 1.5s ease-in-out infinite;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Text
          style={TEXT_STYLES.APP_H3}
          customCss={css`
            align-self: flex-start;
          `}
        >
          "{quote}"
        </Text>
        <Text
          style={TEXT_STYLES.APP_H4}
          customCss={css`
            align-self: flex-end;
            text-align: right;
          `}
        >
          - {author}
        </Text>
        <div
          css={css`
            margin-top: 25px;
            width: 100%
            display: flex;
            justify-content: center;
          `}
        >
          <LoadingIndicator />
        </div>
      </div>
    </div>
  );
}

const SessionButtonBonanza = ({ userCanHostSessions }) => {
  const { user } = useContext(UserContext)
  const sendSegmentEvent = useSendSegmentEvent()

  return (
    <div css={css`
      width: 100%;

      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 8px;
    `}>
      {userCanHostSessions ?
        <div>
          <UnstyledLink to={"/upcoming/create/"} onClick={() => sendSegmentEvent('Clicked Create Session Button', { location: 'scheduleTop'})}>
            <FlowButton buttonStyle={BUTTON_STYLES.SECONDARY}>
              + Create New
            </FlowButton>
          </UnstyledLink>
        </div>
        :
        <>
          {user && user.hostStatus && user.hostStatus === HostStatus.NOT_NOW &&
            <Link to="/host-onboarding/">
              <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} onClick={() => sendSegmentEvent('Host Onboarding Link Clicked (Become a Host button)')}>
                Become a host
              </FlowButton>
            </Link>
          }
        </>
      }
    </div>
  )
}

export const EventsSection = ({ sessions, title, showHostInterestSession = false, displayRecommendedSessionType = false }) => {

  const { refetchSchedulePageData } = useContext(ScheduleContext)

  const sessionsByTimeSlot = groupObjectsByProperty(sessions, session => dayjs(session.start).startOf('hour').valueOf())
  const timeSlots = sortBy(Object.keys(sessionsByTimeSlot)).map(timeSlot => ({ sessions: sessionsByTimeSlot[timeSlot], timeSlot: parseInt(timeSlot) }))

  return (
    <SegmentProvider baseData={{ eventsListSectionTitle: title }}>
      <div className="table-schedule">
        <Row className="schedule-date d-flex justify-content-center">
          {title}
        </Row>
        {sessions.length === 0 ? '' : displayRecommendedSessionType ? 
          <>
          {sessions.map((event, index) => <EventCard
            key={event.id}
            isFirst={index === 0}
            isLast={index === sessions.length - 1 && !showHostInterestSession}
            event={event}
            onActionSuccess={refetchSchedulePageData}
            displayRecommendedSessionType={displayRecommendedSessionType}
          />)}
          </>
          :
          <>
            <div css={css`display: flex; flex-direction: column; gap: 32px;`}>
              {timeSlots.map(({ sessions, timeSlot }) => <EventsTimeSlot timeSlot={timeSlot} key={timeSlot} sessions={sessions} displayRecommendedSessionType={displayRecommendedSessionType} />)}
            </div>
            {showHostInterestSession && <HostInterestSession />}
          </>
        }
      </div>
    </SegmentProvider>
  )
}

const TinyEventCardsEventsList = ({ timeSlots }) => {
  return (
    <div css={css``}>
      {timeSlots.map(({ sessions, timeSlot }, index) =>
        <div key={timeSlot} css={css`
          border: 1px solid ${NEUTRAL_40};
          border-top-width: ${index === 0 ? '1px' : '0px'};
          border-radius: ${index === 0 ? '6px 6px' : '0px 0px'} ${index === timeSlots.length - 1 ? '6px 6px' : '0px 0px'};

          background-color: ${NEUTRAL_20};

          padding: 24px 0px 24px 32px;
          display: flex;
          gap: 20px;
          align-items: center;
          overflow-x: auto;
        `}>
          <Text style={TEXT_STYLES.APP_H5} customCss={css`flex-shrink: 0;`}>{dayjs(timeSlot).format("h:mm A")}</Text>
          {sessions.map(session => <TinyEventCard key={session.id} session={session} />)}
        </div>
      )}
    </div>
  )
}

const TinyEventCard = ({ session }) => {
  const { hostUser, start, duration, hostId, isFakeHostTBDSession, id: eventId } = session
  const { refetchSchedulePageData } = useContext(ScheduleContext)

  // Don't link to session details page if session doesn't actually exist
  const LinkWrapper = useMemo(() => ({ children }) => !isFakeHostTBDSession ?
    <UnstyledLink to={"/session/" + eventId} rel="noopener noreferrer">{children}</UnstyledLink> :
    children,
  [isFakeHostTBDSession, eventId])

  return (
    <LinkWrapper>
      <div css={css`
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        width: 300px;
        height: 106px;

        background-color: white;
        &:hover { background-color: #f3f8ff; }

        display: flex;
        padding: 24px 12px 24px 24px;
        align-items: center;
        gap: 16px;
        user-select: none;


        ${isFakeHostTBDSession && css`
          cursor: inherit;
          &:hover { background-color: #FFFFFF; }
        `}
      `}>
        <div css={css`width: 64px; height: 64px; flex-shrink: 0;`}>
          <HostAvatar showHostAvatarTooltip={hostId !== EMPTY_HOST_ID} hostUser={hostUser} />
        </div>
        <div>
          <Text style={TEXT_STYLES.BODY_2}>{dayjs(start).format("h:mm")}</Text>
          <Text>{duration} mins</Text>
          <Text style={TEXT_STYLES.BODY_2}>w/ {hostUser.displayName}</Text>
        </div>
        <BookingButtonWrapper event={session} onActionSuccess={refetchSchedulePageData} />
      </div>
    </LinkWrapper>
  )
}

const EventsTimeSlot = ({ sessions, timeSlot, displayRecommendedSessionType }) => {

  const { refetchSchedulePageData } = useContext(ScheduleContext)

  const dateAndTime = dayjs(timeSlot)
  const title = dayjs(dateAndTime).format('ddd D @ h A')
  const sectionId = `schedule-${dateAndTime.format('YYYY-MM-DD')}-${dateAndTime.format('H')}`

  return (
    <div id={sectionId}>
      <div css={css`display: flex; margin-bottom: 8px; gap: 12px;`}>
        <Text style={TEXT_STYLES.APP_H5}>
          {title} ({sessions.length} {sessions.length === 1 ? 'session' : 'sessions'})
        </Text>
      </div>
      {<>
        {sessions.map((event, index) => <EventCard
          key={event.id}
          isFirst={index === 0}
          isLast={index === sessions.length - 1}
          event={event}
          onActionSuccess={refetchSchedulePageData}
          displayRecommendedSessionType={displayRecommendedSessionType}
        />)}
      </>}
    </div>
  )
}

const DayOfEvents = memo(({ date, sessions, setDayVisibility, selectedHost, activeThemeFilters, activeDurationFilters }) => {
  const { isHostQualified, user } = useContext(EventsStateContext)

  const onlyShowingChatOnly = getOnlyShowChatOnly(activeThemeFilters)
  const onlyShowingPomodoro = getOnlyShowPomodoro(activeThemeFilters)
  const show60Min = getShow60Minutes(activeDurationFilters)
  const showHostInterestSession = isHostQualified && date === dayjs().format('YYYY-MM-DD')

  // Don't fill the schedule with host TBD sessions if the user is past due or if the user
  // has a host filter selected
  const showHostTBDSessions = selectedHost === undefined && (user === null || user.subscriptionStatus !== SubscriptionStatus.PAST_DUE) && !onlyShowingChatOnly && !onlyShowingPomodoro && show60Min

  const sessionsWithHostTBDCreationOptions = showHostTBDSessions ? fillScheduleWithHostTBDCreatableSessions(sessions) : sessions

  // sort priority (higher -> lower):
  // event start time -> event interactability -> is from a favorite host -> event duration, shorter first -> # of open slots in event -> not Host TBD -> host name alphabetical
  const sortedEvents = useMemo(() => 
    sortBy(
      sortBy(
        sortBy(
          sortBy(
            sortBy(
              sortBy(
                sortBy(
                  sessionsWithHostTBDCreationOptions,
                  'host'
                ),
                event => event.hostId === EMPTY_HOST_ID
              ),
              event => getEffectiveMaxAttendees(event, user) - event.guests.length,
              true
            ),
            'duration'
          ),
          event => event.isFavoriteHost,
          true
        ),
        event => userSessionStatusInteractabilityMap[event.userSessionStatus]
      ),
      event => event.start
    )
  , [sessions])

  const RenderContent = useMemo(() =>
    forwardRef((_, ref) => (
      <div ref={ref} id={`schedule-${date}`} css={css`scroll-margin-top: 190px;`}>
        <EventsSection sessions={sortedEvents} date={date} title={dayjs(date).format('ddd D')} showHostInterestSession={showHostInterestSession} />
      </div>))
    , [sortedEvents, date, showHostInterestSession]
  )

  const TrackedContent = useWithVisibility(RenderContent, setDayVisibility)

  return (sessions.length > 0) && TrackedContent
}, (prevProps, nextProps) => {
  return Object.keys(prevProps).every(prop => JSON.stringify(prevProps[prop]) === JSON.stringify(nextProps[prop]))
})

// lower values get sorted higher on the schedule, if occurring at the same time
// 0 = user is already booked and session can be joined
// 1 = user is already booked but session can't currently be joined
// 2 = can drop-in (immediately joinable)
// 3 = can book
// 4 = user can't interact with session
// 5 = session doesn't actually exist, will be displayed after real sessions
const userSessionStatusInteractabilityMap = {
  [USER_SESSION_STATUS.PARTICIPANT_FUTURE_SESSION]: 1,
  [USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW]: 0,
  [USER_SESSION_STATUS.PARTICIPANT_HAPPENING_NOW_CANCELABLE]: 0,
  [USER_SESSION_STATUS.PARTICIPANT_PAST_SESSION]: 1,
  [USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION_REQUIRES_CONFIRMATION]: 0,

  [USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION]: 3,
  [USER_SESSION_STATUS.JOINABLE_FUTURE_SESSION_REQUEST]: 5,
  [USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW]: 2,
  [USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_NON_USER]: 2,
  [USER_SESSION_STATUS.JOINABLE_WARN_NEW_USERS_LONGER_CHAT_ONLY_SESSION]: 2,
  [USER_SESSION_STATUS.JOINABLE_HAPPENING_NOW_LOADING]: 2,

  [USER_SESSION_STATUS.NOT_JOINABLE_FULL]: 4,
  [USER_SESSION_STATUS.NOT_JOINABLE_HAPPENING_NOW]: 4,
  [USER_SESSION_STATUS.NOT_JOINABLE_PAST]: 4,
  [USER_SESSION_STATUS.NOT_JOINABLE_BAD_SUBSCRIPTION_STATE]: 4,

  [USER_SESSION_STATUS.HOST_FUTURE_SESSION]: 1,
  [USER_SESSION_STATUS.HOST_HAPPENING_NOW]: 0,
  [USER_SESSION_STATUS.HOST_HAPPENING_NOW_CANCELABLE]: 0,
  [USER_SESSION_STATUS.HOST_PAST_SESSION]: 1,
}

export const TimezoneIndicator = () => {
  // This breakpoint comes from some previous schedule-events/bootstrap breakpoints
  const mobileView = useMediaQuery({query: '(max-width:767px)'})
  return (
    <span css={css`
      color: ${TEXT_60_PERCENT};
      font-size: 80%;
      font-weight: 400;

      /* These were taken from bootstrap's Row + Col */
      margin-top: 1rem;
      padding: 0 ${mobileView ? "1px" : "1.5rem"};
    `}>
      All times in {dayjs().format("z")} ({dayjs.tz.guess()})
    </span>
  )
}

const CurrentlyWorkingCountIndicator = ({ numCurrentlyWorking, numCurrentSessions }) => {
  return (
    <div css={css`
      background: #FFFFFF;
      border-radius: 8px;
      flex-shrink: 0;

      @media (max-width: 899px) { width: 100%; }

      padding: 12px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    `}>
        <div css={css`
          border-radius: 100%;
          width: 8px;
          height: 8px;
          background: ${FC_GREEN};
          box-shadow: 0px 0px 4px ${FC_LIGHT_GREEN};

          margin-right: 6px;
        `} />
        <Text><strong>{numCurrentlyWorking}</strong> flowing in {numCurrentSessions > 0 ?
        <><strong>{numCurrentSessions}</strong> session{numCurrentSessions > 1 ? "s" : ""}</> : <>the lounge</>}</Text>
    </div>
  )
}
import "../settings.scss"
import { useContext } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap';

import 'firebase/compat/firestore';

import { UserContext } from '../../../UserProvider';

import dayjs from 'dayjs'
import { SubscriptionStatus } from "../../../../functions/shared/constants";
import { useAutomaticallyFetch } from "../../../fetch/helpers";
import { useGetBillingCustomerPortalUrl, useGetReferralsData } from "../../../fetch/endpoints";

const SubscriptionAndBilling = () => {
  const { user } = useContext(UserContext)

  const { result: portalURL } = useAutomaticallyFetch(useGetBillingCustomerPortalUrl, {}, { transform: result => result.url, condition: user.stripeCustomerId !== undefined })
  let { result: referrals, fetching: loading } = useAutomaticallyFetch(useGetReferralsData, {}, { transform: result => result.referrals })
  if (referrals === null) { referrals = [] }

  return (
    <Container>
      <h2 className="header-settings">Subscription & Billing</h2>
      <Card className="p-4 settings-card--md">
        <h6>Flow Club Unlimited</h6>
        Thanks for being a Flow Club member{user.hostedSessions ? " and host" : ""}!
        {user && user.subscriptionStatus === SubscriptionStatus.TRIALING_NO_CARD && user.trialEnd ?
          <span>You have {dayjs(user.trialEnd.toDate()).fromNow(true)} left on your free trial.
            You will need to <a href="/plans/">choose a Flow Club Membership before {dayjs(user.trialEnd.toDate()).format('ddd, MMM D')}</a> to continue accessing the community.</span>
          : ""}
        <br />
        <div className="mt-16">
          {portalURL ?
            <a href={portalURL} >Manage your membership</a>
            : user.stripeCustomerId ? <span>Loading...</span> : ""}
        </div>
      </Card>

      <Row className="mt-32 mb-24">
        <Col xs={12}>
          <h5>Referral Program Status</h5>
          {loading ?
            <Spinner animation="grow" variant="primary" />
            :
            referrals.length > 0 ?
              <span className="settings-success">
                You've earned {referrals.length} free month{referrals.length > 1 ? "s!" : ""}
              </span>
              :
              <span className="settings-default">
                You haven't earned any free months yet - send them an invite to get started!
              </span>
          }
        </Col>
      </Row>
      {referrals.length > 0 ?
        referrals.map((referral, index) => (
          <Card className="my-3 p-4" key={index}>
            <span style={{"font-weight": "bold"}}>{referral.action}{referral.earned ? ` on ${referral.earned}` : ""}!</span>
            You earned a free month{referral.applied ? ` (${referral.applied})` : ""}!
          </Card>
        ))
        : ""}

    </Container>
  )
}

export default SubscriptionAndBilling
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import './Schedule.scss'
import './Session.scss'
import dayjs from 'dayjs' 
import Linkify from 'linkifyjs/react';
import { useContext, useEffect, useState} from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  useParams,
  useHistory,
  useLocation,
  Link,
} from 'react-router-dom';
import 'firebase/compat/firestore';
import { Helmet } from "react-helmet";

import { UserContext, useGetDisplayName } from '../UserProvider';
import {
  getEffectiveMaxAttendees,
  getHostClaimability,
  sessionIsInteractable,
} from './SessionUtils';
import { CoworkerList } from './CoworkerList';
import { SadOctopus } from '../components/sad-octopus';
import { InviteInfoContext } from '../InviteInfoWrapper';
import { DefaultBanner } from '../components/banners';
import { BookingButtonWrapper } from '../components/BookingButtonWrapper';
import { LimitedSeats } from './LimitedSeats';
import { SegmentProvider } from '../wrappers/SegmentProvider';
import { HostDisplayName } from './HostDisplayName';
import { useQuery } from '../utils';
import { useModal } from '../wrappers/MagnificentlyMunificentModalManager';
import { ClaimHostModal } from './modals/ClaimHostModal';
import { HostingAlreadyClaimedModal } from './modals/HostingAlreadyClaimedModal';
import { SecondaryInteractionOptions } from './SecondaryInteractionOptions';
import { useAutomaticallyFetch, useFetchSucceeded } from '../fetch/helpers';
import { useGetEventDetails, useUpdateSessionStartAndEndTimes } from '../fetch/endpoints';
import _ from 'lodash';
import { ONE_MINUTE, USER_SESSION_STATUS } from '../../functions/shared/constants';
import { getGoogleAddToCalendarUrl, getOutlookAddToCalendarUrl } from '../../functions/shared/calHelpers';
import { downloadICSFile } from './icsHelpers'
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { BRONZE } from '../emotionVariables';
import { Text, FlowButton, BUTTON_STYLES, TEXT_STYLES } from '../components/flowComponents';
import { getImageForHost, toTitleCase } from '../../functions/shared/helpers';
import { TooltipWrapper } from '../components/TooltipWrapper';
import { shift, offset } from '@floating-ui/react-dom';
import { toastSuccess, toastError } from '../components/utils/toaster';
import { StartTimeOverride } from '../components/create-session/CreateSessionConfirmDetail';
import { ClubWithLink } from './EventCard';
import { OutOfSessionProgressBars } from '../ParticipantApp/SessionStageProgressBars';

const linkifyOptions = {
  attributes: {rel: "noopener noreferrer"},
  target: {
    url: '_blank'
  },
}

const ContentRow = ({children}) => (
  <Row css={css`
    padding: 24px 0px 24px 0px;
    border-top: 1px solid #E6E6E6;
  `}>{children}</Row>
)

const Label = ({children}) => (
  <div className="schedule-detail text-uppercase" css={css`margin-bottom: 8px;`}>
    {children}
  </div>
)

const CantJoinMessage = ({ flowSession }) => {
  const { user } = useContext(UserContext)
  const location = useLocation()
  const { inviteValidationInvitedBy: invitedBy } = useContext(InviteInfoContext)

  const sessionClosedToBooking = flowSession !== null && !sessionIsInteractable(flowSession.userSessionStatus)
  const sessionHasEnded = flowSession !== null && Date.now() > flowSession.end.getTime()
  const redirectedFromSessionLobby = location.state !== undefined && location.state.redirectedFromSessionLobby

  if (sessionClosedToBooking) {
    return <DefaultBanner>
      {!sessionHasEnded ?
        "It's too late to join this session" :
        "This session has ended"
      }
      {invitedBy ?
        `, but ${invitedBy.name} would still like to co-work with you! ` :
        ". "
      }
      Join a new Flow Club by <a href="/upcoming">browsing the schedule!</a>
    </DefaultBanner>
  }

  if (redirectedFromSessionLobby) {
    if (flowSession !== null && flowSession.userSessionStatus === USER_SESSION_STATUS.NOT_JOINABLE_FULL) {
      return <DefaultBanner>Sorry, this session is full! Join a new Flow Club by <a href="/upcoming">browsing the schedule!</a></DefaultBanner>
    }
    if (user === null) {
      return <DefaultBanner>Please sign up first, then try joining again.</DefaultBanner>
    }
  }

  return null
}

const AddToCalendarButton = ({ flowSession }) => {
  const sessionClosedToBooking = flowSession !== null && !sessionIsInteractable(flowSession.userSessionStatus)
  if (flowSession === null || sessionClosedToBooking ) return null

  const AddToCalendarOptions = () => {
    const sendSegmentEvent = useSendSegmentEvent()
    const openEventInCalendar = async (calendar) => {
      if (calendar === 'Apple Calendar') {
        await downloadICSFile(flowSession)
      } else {
        const calendarLink = calendar === 'Google Calendar' ? getGoogleAddToCalendarUrl(flowSession) :
          getOutlookAddToCalendarUrl(flowSession)
        window.open(calendarLink, '_blank')
      }
      sendSegmentEvent('Clicked Add to Calendar', {
        calendar,
        eventId: flowSession.id,
      })
    }
    return (
      <div css={css`display: flex; flex-direction: column; align-items: center; gap: 8px;`}>
        {['Google Calendar', 'Outlook', 'Apple Calendar'].map((calendar, index) => (
          <FlowButton
            fillAvailableWidth
            buttonStyle={BUTTON_STYLES.OUTLINE_DARK}
            onClick={() => openEventInCalendar(calendar)}
            key={index}
          >
            {calendar}
          </FlowButton>
        ))}
      </div>
    )
  }
  const tooltipContents = <AddToCalendarOptions />
  return (
    <TooltipWrapper TooltipContents={tooltipContents} useFloatingArgs={{ placement: 'bottom' , middleware: [shift({ padding: 8 }), offset(4)]}} showOnMobile={true} retainMousedOverInsideTooltip={true}>
      <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>
        Add to Calendar
      </FlowButton>
    </TooltipWrapper>
  )
}

const SessionRescheduleAdminForm = ({ session, hostUser }) => {
  const { user } = useContext(UserContext)
  const hostDisplayName = useGetDisplayName(hostUser)
  const [ showForm, setShowForm ] = useState(false)
  const { start, duration } = session
  const sessionStart = dayjs(start).tz(hostUser.timezone)
  console.log("sessionStart", sessionStart.format('MM/DD/YY HH:mm a z'))
  const defaultTimeOverride = sessionStart.format('MM/DD/YY HH:mm')
  const [startTimeOverride, setStartTimeOverride] = useState(defaultTimeOverride)
  const [explanation, setExplanation] = useState('Originally scheduled off by an hour because of Daylight Savings Time. Sorry!')
  const { performFetch: rescheduleSession, fetching: reschedulingSession } = useUpdateSessionStartAndEndTimes()

  const submitForm = async () => {
    const startTime = effectiveDate.toDate().toISOString()
    console.log(`new start time: ${startTime}`)
    const { success } = await rescheduleSession({ sessionId: session.id, newStartTime: startTime, explanation })
    if (success) {
      setStartTimeOverride(defaultTimeOverride)
      setExplanation('')
      toastSuccess({ message: 'Session rescheduled to ' + effectiveDate.format('ddd, MMM D, h:mma z') })
    } else { 
      toastError({ message: 'Failed to reschedule session' })
    }
  }

  // "valid date" here is a very generous term, but roll with it
  const startTimeOverrideIsValid = dayjs(startTimeOverride).isValid() && startTimeOverride.match(/^[0-1][0-9]\/[0-3][0-9]\/[0-9][0-9] [0-2][0-9]:[0-6][0-9]$/) !== null
  let effectiveDate = startTimeOverrideIsValid ? dayjs.tz(startTimeOverride, "MM/DD/YY HH:mm", hostUser.timezone) : null
  console.log('effectiveDate', effectiveDate?.format('MM/DD/YY HH:mm a z'))
  if (effectiveDate !== null) {
    const startOfDayMinutesDSTOffset = effectiveDate.startOf('day').utcOffset() - effectiveDate.add(1, 'day').startOf('day').utcOffset() // thanks DST
    effectiveDate = effectiveDate.add(startOfDayMinutesDSTOffset, 'minute')
  }

  const hostRelativeTimezoneOffset =
    hostUser.timezoneOffset === undefined || user.timezoneOffset === hostUser.timezoneOffset ?
      null :
      `${(user.timezoneOffset - hostUser.timezoneOffset) / 60} hrs (Your local time: ${dayjs().tz(user.timezone).startOf('hour').format('h a')} -> ${hostDisplayName}'s local time: ${dayjs().tz(hostUser.timezone).startOf('hour').format('h a')})`


  const adminBackground = css`
    border-radius: 8px;
    border: 1px solid rgba(160, 187, 217, 0.2);
    padding: 12px;

    background: repeating-linear-gradient(
      -45deg,
      rgba(160, 187, 217, 0.2),
      rgba(160, 187, 217, 0.2) 30px,
      transparent 30px,
      transparent 60px
    );
    flex-direction: column;
    display: flex;
    gap: 8px;
  `
  return (
    showForm ?
    <div css={adminBackground}>
      <Text style={TEXT_STYLES.APP_H6}>Admin reschedule session</Text>
      <Text customCss={css`color: orange;`}>Host timezone: {hostUser.timezone}</Text>
      {hostRelativeTimezoneOffset !== null && <div>
        <Text style={TEXT_STYLES.BODY_2} customCss={css`color: orange;`}>Timezone offset from local time: {hostRelativeTimezoneOffset}</Text>
      </div>}
      <Text>Original time: {sessionStart.format('dddd, MMMM DD hh:mm A')} - {sessionStart.add(duration, 'minute').format('hh:mm A z')}</Text>
      {effectiveDate !== null &&
      <Text customCss={css`margin-top: 12px;`}>
        Rescheduling to: {`${effectiveDate.format("dddd, MMMM DD hh:mm A")} - ${effectiveDate.add(duration, 'minute').format("hh:mm A z")}`}({effectiveDate.diff(sessionStart, 'hours')} hours later)
      </Text>}
      <StartTimeOverride startTimeOverride={startTimeOverride} setStartTimeOverride={setStartTimeOverride} startTimeOverrideIsValid={startTimeOverrideIsValid} timezone={hostUser.timezone} adminBackground={false} />
      <Form.Group css={css`margin-bottom: 12px;`}>
        <Form.Label>Explanation</Form.Label>
        <input
          type="text"
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
          placeholder="Why is this session being rescheduled?"
          maxLength={70}
          className="form-control"
        />
      </Form.Group>
      <FlowButton onClick={submitForm} disabled={reschedulingSession} buttonStyle={BUTTON_STYLES.PRIMARY} fillAvailableWidth>Rescehdule session</FlowButton>
    </div> :
    <div css={adminBackground}>
      <FlowButton onClick={() => setShowForm(true)} buttonStyle={BUTTON_STYLES.OUTLINE_DARK} fillAvailableWidth>Reschedule session</FlowButton>
    </div>
  )

}

function Session() {
  const { user, userHasAdminPermissions } = useContext(UserContext)
  const history = useHistory()
  const { sessionId } = useParams()
  let { inviteCode, inviteValidationInvitedBy: invitedBy } = useContext(InviteInfoContext)

  const { result: flowSession, fetching, error, performFetch } = useAutomaticallyFetch(
    useGetEventDetails,
    { eventId: sessionId },
    {
      transform: result =>
      ({
        ...(_.pick(result.event,
          [
            "id", "title", "duration", "subtitle", "description", "guests", "musicDescription", "tags", "maxAttendees", "agenda", "canceledSeatOpen", "eventTypeDescription", "userSessionStatus", "visibility", "chatOnly", "pomodoro", "breakCheckIns", "hostGoalDescription", "hostGoalEmoji", "eventTypeId", "privateSession", "hostId",
            "host", "hostUser", // joined objects
          ]
        )),
        start: new Date(result.event.start),
        end: new Date(result.event.end),
      }),
      refetchEvery: ONE_MINUTE * 5
    }
  )
  const loading = !useFetchSucceeded(fetching, error)
  const refetchSessionData = () => performFetch({ eventId: sessionId })

  // this logic is a bit deprecated at this point but probably not harming anything
  const BackLink = () => {
    return (
      history.length > 1 ?
        <Link to="#" className="my-5" onClick={
          (e) => { e.preventDefault(); history.goBack(); }
        }>← Go Back</Link>
        :
        (user || localStorage.getItem('userDisplay')) ?
          <Link to="/upcoming" className="my-5">← Back to the schedule</Link>
          :
          flowSession !== null && flowSession.visibility === 'public' ?
            <Link to={"/upcoming"} className="my-4">← Can't make this session? See the rest of the schedule</Link>
            :
            ""
    )
  }

  const BackLinkButton = () => {
    return (
      history.length > 1 ?
        <Button variant="primary" className="my-3" onClick={
          (e) => { e.preventDefault(); history.goBack(); }
        }>Go Back</Button>
        :
        (user || localStorage.getItem('userDisplay')) ?
          <Button href="/upcoming" className="my-3">Back to schedule</Button>
          :
          inviteCode ?
            <Button href={"/upcoming"} className="my-3">
              See the rest of the schedule
            </Button>
            :
            ""
    )
  }

  const effectiveMaxAttendees = flowSession !== null ? getEffectiveMaxAttendees(flowSession, user) : null

  const sessionNotFound = error !== null

  const { setActiveModal } = useModal()
  const claimHosting = () => {
    const { isEmptyHost, userCanClaimHosting, alreadyBooked } = getHostClaimability({ event: flowSession, user })
    if (!isEmptyHost) {
      setActiveModal(HostingAlreadyClaimedModal)
    } else if (userCanClaimHosting) {
      setActiveModal(ClaimHostModal, { event: flowSession, alreadyBooked, confirmImmediately: true, onSuccess: refetchSessionData })
    }
  }

  const query = useQuery()
  const claimingHostFromEmailLink = query.get('claimHost') === '1'
  useEffect(() => {
    if (claimingHostFromEmailLink && flowSession !== null) {
      claimHosting()
      query.delete('claimHost')
      history.replace(`?${query.toString()}`)
    }
  }, [claimingHostFromEmailLink, flowSession])

  const privateSession = flowSession !== null && flowSession.privateSession
  const hostingPrivateSession = privateSession && flowSession.userSessionStatus === USER_SESSION_STATUS.HOST_FUTURE_SESSION

  const { musicDescription, title, hostUser, guests, canceledSeatOpen, start, duration, description, tags, visibility, chatOnly, breakCheckIns, pomodoro, hostGoalDescription, hostGoalEmoji, eventTypeId } = flowSession || {}
  const { musicStyle } = hostUser || {}
  const hostDisplayName = useGetDisplayName(hostUser)
  const music = musicDescription  || musicStyle

  return (
    <SegmentProvider eventLabel={'Session Detail Page'} baseData={{ sessionId }}>
      <div css={css`min-height: 100vh;`}>
        {flowSession &&
          <Helmet>
            <title>{title}</title>
            <meta name='robots' content='noindex, follow' />
          </Helmet>}
        <div>
          <Container className="mt-5">
            <CantJoinMessage flowSession={flowSession} />
            <BackLink />
          </Container>
          <Container>
            {sessionNotFound &&
              <div className="error-container text-center">
                <SadOctopus />
                <h2 className="mt-5">Sorry, couldn't find that session</h2>
                <div>
                  It may have been canceled or the link may be wrong.
                  <br />
                  Visit the schedule to find your next session.<br />
                  <BackLinkButton />
                </div>
              </div>
            }
            {!sessionNotFound && loading && <Spinner animation="grow" variant="primary" />}
            {!loading && flowSession && (
              <div className="mt-4">
                <div className="d-flex justify-content-center">
                  <img src={getImageForHost(hostUser, 'image64')} css={css`width: 64px; height: 64px;`} className="text-center rounded-circle" alt={hostDisplayName} />
                </div>
                <div className="d-flex justify-content-center">
                  <h1 className="text-center" css={css`margin: 17px 0px 27px 0px;`}>{title}</h1>
                </div>
                <div css={css`display: flex; align-items: center; flex-flow: column;`} className="mb-3">
                  <BookingButtonWrapper event={flowSession} onActionSuccess={refetchSessionData} showInviteGuestsLink={hostingPrivateSession} />
                  <div css={css`margin-top: 16px;`}>
                    <SecondaryInteractionOptions event={flowSession} onActionSuccess={refetchSessionData} />
                  </div>
                  {user === null && <AddToCalendarButton flowSession={flowSession} />}
                  {userHasAdminPermissions && 
                    <SessionRescheduleAdminForm session={flowSession} hostUser={hostUser} />
                  }
                </div>
                <div className="justify-content-center text-center mb-3">
                  <LimitedSeats guests={guests} maxAttendees={effectiveMaxAttendees} canceledSeatOpen={canceledSeatOpen} />
                </div>
              </div>
            )}
          </Container>
          {!loading && flowSession && (
            <Container>
              <div>
                {privateSession && <ContentRow>
                  <Col>
                    <Label>Access</Label>
                    {visibility === 'private' ?
                      <Text customCss={css``}>Only those with the link can join. This session is not visible on the Flow Club schedule.</Text> :
                      <Text customCss={css`color: ${BRONZE}; font-weight: bold;`}>Only members of {toTitleCase(visibility)}</Text>
                    }
                  </Col>
                </ContentRow>}
                <ContentRow>
                  <Col>
                    <Label>
                      Date
                    </Label>
                    <div className="schedule-time">
                      {dayjs(start).format('ddd, MMM D')}
                    </div>
                  </Col>
                  <Col>
                    <Label>
                      Starts at
                    </Label>
                    <div className="schedule-time">
                      {dayjs(start).format('h:mma z')}
                    </div>
                  </Col>
                  <Col>
                    <Label>
                      Duration
                    </Label>
                    <div className="schedule-time">
                      {duration} mins
                    </div>
                  </Col>
                  <Col>
                    <Label>
                      Hosted By
                    </Label>
                    <div className="schedule-time">
                      <HostDisplayName event={flowSession} onHostClaimSuccess={refetchSessionData} />
                    </div>
                  </Col>
                </ContentRow>
                <ContentRow>
                  <Col>
                    <Label>
                      Agenda
                    </Label>
                    <div>
                      <OutOfSessionProgressBars stages={flowSession.agenda} />
                    </div>
                  </Col>
                  {music &&
                    <Col>
                      <Label>
                        Music
                      </Label>
                      <div>
                        🎵 {music}
                      </div>
                    </Col>
                  }
                </ContentRow>
                <ContentRow>
                  <Col>
                    <Label>
                      Description
                    </Label>
                    <Linkify tagName="div" options={linkifyOptions}>
                      {description}
                    </Linkify>
                    <br/>
                    {hostGoalDescription && hostGoalEmoji &&
                      <div>
                        {hostDisplayName} is working on: {hostGoalEmoji} {hostGoalDescription}
                      </div>
                    }
                  </Col>
                </ContentRow>
                {(tags.length > 0 || chatOnly || breakCheckIns || pomodoro) &&
                  <ContentRow>
                    <Col>
                      <Label>
                        Theme
                      </Label>
                      <div>
                      <ClubWithLink eventTypeId={eventTypeId} style="badge" />
                      {chatOnly && <Badge css={css`background-color: #D5E3E0;`} className="mr-2">⌨️ Chat-only (No verbal sharing)</Badge>}
                      {breakCheckIns && <Badge css={css`background-color: #D5E3E0;`} className="mr-2">✅ Check-in during breaks</Badge>}
                      {pomodoro && <Badge css={css`background-color: #D5E3E0;`} className="mr-2">🍅 Pomodoro</Badge>}
                        {tags && tags.map((tag) => (
                          <Badge css={css`background-color: #D5E3E0;`} className="mr-2" key={tag}>{tag}</Badge>
                        ))}
                      </div>
                    </Col>
                  </ContentRow>
                }
                <ContentRow>
                  <Col md="12">
                    <div>
                      <Label>You'll be coworking with</Label>
                      <CoworkerList event={flowSession} user={user} inviteTag={invitedBy && invitedBy.tag ? invitedBy.tag : null} />
                    </div>
                  </Col>
                </ContentRow>
              </div>
            </Container>
          )}
        </div>
      </div>
    </SegmentProvider>
  );
}

export default Session;
import firebase from 'firebase/compat/app';
import { useContext } from 'react';
import { translateAuthErrorCodeToMessage } from '../components/utils/auth';
import { useLoginSuccessCallback } from './useLoginSuccessCallback';
import { UserContext } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider()
GOOGLE_PROVIDER.setCustomParameters({
  prompt: 'select_account',
})

export const FIREBASE_AUTH_METHODS = {
  GOOGLE: 'google',
  EMAIL_PASSWORD: 'email_password'
}

export const authFunctions = {
  [FIREBASE_AUTH_METHODS.GOOGLE]: async () => await firebase.auth().signInWithPopup(GOOGLE_PROVIDER),
  [FIREBASE_AUTH_METHODS.EMAIL_PASSWORD]: async (...args) => await firebase.auth().signInWithEmailAndPassword(...args)
}

// shared between both email login and google oauth login; distinguished by firebaseAuthMethod
export const useLogin = ({ firebaseAuthMethod, onSuccess = () => { }, onFailure = () => { } }) => {
  const { setLoginInProgress } = useContext(UserContext);
  const sendSegmentEvent = useSendSegmentEvent()

  const setLoggedInSuccessResult = useLoginSuccessCallback(onSuccess, "login")

  const signInSuccessful = (signInResult) => {
    sendSegmentEvent("User Logged In", { accountExisted: !signInResult.additionalUserInfo.isNewUser, firebaseAuthMethod })
    const authUser = signInResult.user
    if (signInResult.additionalUserInfo.isNewUser) {
      // Delete the newly created firebase auth user, and display an error message -- this wasn't a valid login that we recognize
      // This should only ever happen with google logins
      firebase.auth().currentUser.delete()
      onFailure(`Sorry, but it doesn't look like you have an account with Flow Club yet (using the email ${signInResult.additionalUserInfo.profile.email}). Did you mean to sign up?`)
    } else {
      setLoginInProgress(true)
      setLoggedInSuccessResult(authUser)
    }
  }

  const authFunction = authFunctions[firebaseAuthMethod]
  const attemptAuthentication = async (...args) => {
    try {
      signInSuccessful(await authFunction(...args))
    } catch (error) {
      onFailure(translateAuthErrorCodeToMessage(error.code))
    }
  }

  return attemptAuthentication
}
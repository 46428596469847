/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useMediaQuery } from 'react-responsive';
import { InterstitialContext } from './Onboarding/WelcomeInterstitial';
import { NowLaterPane } from './Onboarding/NowLaterPane';
export const FindATimeToFlow = () => {

  const largeScreenView = useMediaQuery({query: '(min-width: 769px)'})

  return (
    <InterstitialContext.Provider value={{
      largeScreenView,
    }}>
      <div css={css`min-height: 100vh;`}>
        <NowLaterPane title="Find a time to flow" showUpcoming />
      </div>
    </InterstitialContext.Provider>
  )
}

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import "../settings.scss"
import { useContext, useState } from "react";

import { useForm } from 'react-hook-form';
import {
  Link,
} from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { NEUTRAL_70 } from '../../../emotionVariables';
import { Text, ToggleSwitchButton } from '../../flowComponents';
import { useSendSegmentEvent } from '../../../wrappers/SegmentProvider';
import { useSetUserPreferences } from '../../../fetch/endpoints';
import {toastError, toastSuccess } from '../../utils/toaster';
import { HostStatus } from '../../../../functions/shared/constants';
import { ConnectGoogleCalendar } from './ConnectGoogleCalendar';

import 'firebase/compat/auth';

import { UserContext, usePreferences } from '../../../UserProvider';
import { functionsHost } from "../../../firebase"


const PLACEHOLDER_EMAIL = "email@gmail.com";

function CalendarInvites () {
  const { user, authUser } = useContext(UserContext);
  const [isAddingCalendar, setIsAddingCalendar] = useState(false);
  const { register, handleSubmit } = useForm();
  const [isUpdating, setIsUpdating] = useState(false);
  const sendSegmentEvent = useSendSegmentEvent()
  const { performFetch: setUserPreferences, fetching: updatingUserPreferences } = useSetUserPreferences()
  const { disableCalendarInvites: savedDisableCalendarInvites } = usePreferences()
  const [disableCalendarInvites, setDisableCalendarInvites] = useState(savedDisableCalendarInvites)


  // additionalCalendarEmails is currently a dict, grab just the keys
  // @ts-ignore
  const additionalCalendarEmailsDict = user.additionalCalendarEmails || {};
  const additionalEmails = Object.keys(additionalCalendarEmailsDict);

  /**
   * updates the user's calendar emails
   * @param updateObject the update object to send to the user update API
   */
  async function updateUserEmails(updateObject) {
    // update state
    setIsUpdating(true);
    // @ts-ignore
    const token = await authUser.getIdToken();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        updateObject: updateObject,
      })
    };
    await fetch(`${functionsHost}updateUser/`, requestOptions)
    .catch((e) => {
      toastError({
        message: e.message,
      });
    });
    toastSuccess({
      message: "Update saved",
    });
    setIsUpdating(false);
    setIsAddingCalendar(false);
  }

  /**
   * Adds a calendar email to the user
   * @param data form data
   */
  async function onSubmit(data) {
    const additionalCalendarEmail = data.newEmail;
    let updateObject = {
      // must use the original object, as this entire dict will be overwritten (stateless)
      additionalCalendarEmails: additionalCalendarEmailsDict,
    };
    updateObject.additionalCalendarEmails[additionalCalendarEmail] = true;
    return await updateUserEmails(updateObject);
  }

  /**
   * Remove a calendar email from the user
   * @param email email address to remove
   */
  async function deleteEmail(email) {
    delete additionalCalendarEmailsDict[email];
    // https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
    const updateObject = {
      additionalCalendarEmails: additionalCalendarEmailsDict,
    };
    return await updateUserEmails(updateObject);
  }

  const toggleCalendarInvites = async (togglingTo) => {
    sendSegmentEvent("Calendar Invites Toggled", { togglingTo })
    setDisableCalendarInvites(!togglingTo)
    const { success, error } = await setUserPreferences({ newPreferenceData: { disableCalendarInvites: !togglingTo } })
    if (success) {
      toastSuccess({ message: `Calendar invites ${togglingTo ? 'enabled' : 'disabled'}.` })
    } else {
      toastError({ message: error })
    }
  }

  return(
    <div>
      <h4>Calendar Invites</h4>
      <div css={css`margin: 12px 0px;`}>
        <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;`}>
          <Text>Receive calendar invites for Flow Club sessions</Text>
          <ToggleSwitchButton selected={!disableCalendarInvites} setSelected={(togglingTo) => toggleCalendarInvites(togglingTo)} loading={updatingUserPreferences} />
        </div>
        {disableCalendarInvites ?
        <Text customCss={css`color: ${NEUTRAL_70};`}>We will <strong>NOT</strong> send you calendar invites whenever you book{user.hostStatus === HostStatus.ACTIVE ? " or create" : ""} a session</Text> :
        <Text customCss={css`color: ${NEUTRAL_70};`}>We will send you calendar invites to your sessions to help you time block and structure your calendar.</Text>}
      </div>
      <div css={css`margin: 12px 0px;`}>
        <Link to="/settings/clubs-and-hosts">Manage calendar invites for sessions created by your favorite hosts.</Link>
      </div>
      { !disableCalendarInvites &&
      <>
      <div className="row" css={css`margin-top: 8px;`}>
        <div className="col-sm">
          <h6>Calendar Emails</h6>
          <Text>When you book sessions, you will receive calendar invites to each of the emails below:</Text>
          <br/>
          {/* @ts-ignore */}
          <div className="login-email">{authUser.email} (Main account email)</div>
          { additionalEmails.map((item, _) => (
            <div className="row">
              <div className="col-sm">{item}</div>
              <div className="col-sm"><Link to="#" onClick={() => { deleteEmail(item) }}>Delete</Link></div>
            </div>
            ))}
          <br/>
          { isAddingCalendar ? 
            <div>
              <Link to="#" onClick={()=>{setIsAddingCalendar(false)}}>Cancel</Link>
              {/* @ts-ignore */}
              <h6 style={{'padding-top': "8px"}}>Add New Calendar</h6>
              <Form onSubmit={handleSubmit(onSubmit)} className="AddCalendarForm">
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Email Address</Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                          type="text"
                          name="newEmail"
                          ref={register}
                          placeholder={PLACEHOLDER_EMAIL}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Button
                  variant="primary"
                  type="submit"
                  block
                  className="my-3"
                  disabled={isUpdating}>
                    { isUpdating ?
                      <span>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                        Saving...
                      </span> :
                      <span>Save</span>
                    }
                </Button>
              </Form>
            </div> :
            <p>
              <Link to="#" onClick={()=>{setIsAddingCalendar(true)}}>
                Add New Calendar
              </Link>
            </p>
          }
        </div>
      </div>
      <ConnectGoogleCalendar />
      </>
      }

    </div>
  );
}

export default CalendarInvites;
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import "../settings.scss"
import { useContext, useState } from "react";

import { useForm } from 'react-hook-form';

import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { NEUTRAL_70 } from '../../../emotionVariables';
import { UserContext, usePreferences } from '../../../UserProvider';
import { Text, ToggleSwitchButton } from '../../flowComponents';
import { LogoutLink } from "../../../auth/logout";
import { useFetchSucceeded } from "../../../fetch/helpers";
import { useUpdateUser, useSetUserPreferences } from "../../../fetch/endpoints";
import { useSendSegmentEvent } from "../../../wrappers/SegmentProvider";
import Icon, { TYPE_LINKEDIN_SQUARE, TYPE_TWITTER_SQUARE, TYPE_LINK } from '../../../daily/daily-components/Icon/Icon';
import { toastSuccess, toastError } from '../../utils/toaster';
import { checkForOnlyEmojisAndMaxLength, removeEmojis} from '../../../utils';
import { EmojiHelpIcon } from '../../EmojiOverlay';

function UpdateProfile({ onSuccess = () => { }, showHeader = true }) {
  const { user, authUser } = useContext(UserContext);
  const { register, handleSubmit } = useForm();
  const { hideDisplayNameEmojis: hideDisplayNameEmojisSaved } = usePreferences()
  const [ hideDisplayNameEmojis, setHideDisplayNameEmojis ] = useState(hideDisplayNameEmojisSaved || false)
  const [ localDisplayName, setLocalDisplayName ] = useState(user.displayName || '')
  const [ localEmojis, setLocalEmojis ] = useState(user.emojis || '')

  const { performFetch: updateUser, fetching: updatingUser } = useUpdateUser()
  const { performFetch: setUserPreferences, fetching: updatingUserPreferences, error: setUserPreferencesError } = useSetUserPreferences()
  const setUserPreferencesSuccess = useFetchSucceeded(updatingUserPreferences, setUserPreferencesError)

  const sendSegmentEvent = useSendSegmentEvent()
  const fieldDisplayMapping = {
    firstName: "First Name",
    lastName: "Last Name",
    displayName: "Preferred Display Name",
    emojis: "Emojis",
    location: "Current Location",
    pronouns: "Pronouns",
    pronunciation: "Preferred Pronunciation",
    company: "Company or Organization",
    linkedin: "LinkedIn URL",
    twitter: "Twitter/X URL",
    flowActivities: "Flow Activities",
    linkUrl: "External Link URL",
    linkTitle: "External Link Title",
    inProgressCertifications: "In-Progress Certifications & Licenses",
    completedCertifications: "Completed Certifications & Licenses",
  }
  const onSubmit = async (data) => {
    sendSegmentEvent("Profile Changes Submitted")
  
    const { firstName, lastName, displayName, emojis, location, pronouns, pronunciation, company, linkedin, twitter, flowActivities, linkUrl, linkTitle, inProgressCertifications, completedCertifications } = data
    
    // In a sane world this wouldn't be three separate actions, but eh, such is not the world in which we are fated to live
    const [_, updateUserResult] = await Promise.all([
      authUser.updateProfile({
        displayName: firstName + ' ' + lastName
      }),
      updateUser({
        updateObject: {
          firstName,
          lastName,
          fullName: firstName + ' ' + lastName,
          displayName: displayName || firstName + ' ' + lastName,
          emojis: hideDisplayNameEmojis ? '' : emojis,
          location,
          pronouns,
          pronunciation,
          company,
          linkedin,
          twitter,
          linkUrl,
          linkTitle,
          flowActivities,
          inProgressCertifications,
          completedCertifications,
        }
      }),
    ])
    if (hideDisplayNameEmojisSaved === undefined || hideDisplayNameEmojis !== hideDisplayNameEmojisSaved) {
      await setUserPreferences({ newPreferenceData: { hideDisplayNameEmojis } })
    }
    const { success, result } = updateUserResult
    const { errorFields } = result || {}
    if (success) {
      onSuccess()
      if (errorFields.length > 0) {
        toastSuccess({ message: `Successfully updated your profile, but we removed temporarily restricted characters from the following fields: ${errorFields.map(({ key }) => `${fieldDisplayMapping[key] || key}`).join(', ')}`, duration: 5000 })
      } else {
        toastSuccess({ message: "Successfully updated your profile!"})
      }
    } else {
      toastError({ message: "Error updating your profile. Please try again."})
    }
  };

  const checkAndRemoveEmojis = (e) => {
    const inputElement = e.target
    const cleanedValue = removeEmojis(inputElement.value)
    inputElement.value = cleanedValue
    return cleanedValue
  }

  return (
    <Container>
      {showHeader &&
      <>
        <h2>My Profile</h2>
        {user && <div className="mt-3 text-right">Logged in as {user.email} (<LogoutLink />)</div>}
      </>
      }
      <Form onSubmit={handleSubmit(onSubmit)} className="UpdateInfoForm">
        <Form.Row>
          <Form.Group as={Col} md="6" className="required">
            <Form.Label>{fieldDisplayMapping.firstName}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="firstName"
                ref={register}
                placeholder="Mihaly"
                defaultValue={user && user.firstName}
                required
                onChange={checkAndRemoveEmojis}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="6" className="required">
            <Form.Label>{fieldDisplayMapping.lastName}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="lastName"
                ref={register}
                placeholder="Csikszentmihalyi"
                defaultValue={user && user.lastName}
                required
                onChange={checkAndRemoveEmojis}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6" className="required">
            <Form.Label>{fieldDisplayMapping.displayName}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="displayName"
                ref={register}
                placeholder="Mihaly C"
                defaultValue={user.displayName}
                required
                onChange={(e) => {
                  const cleanedValue = checkAndRemoveEmojis(e)
                  setLocalDisplayName(cleanedValue)}
                }
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>{fieldDisplayMapping.pronouns}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="pronouns"
                ref={register}
                defaultValue={user.pronouns}
                placeholder="He/Him, She/Her, They/Them"
                onChange={checkAndRemoveEmojis}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <div css={css`display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;`}>
              <Text>Show emojis next to name</Text>
              <ToggleSwitchButton selected={!hideDisplayNameEmojis} setSelected={(togglingTo) => setHideDisplayNameEmojis(!togglingTo)} loading={updatingUserPreferences} />
            </div>
            { hideDisplayNameEmojis ?
              <Text customCss={css`color: ${NEUTRAL_70};`}>We WILL NOT show emojis next to your display name or other users' display names in sessions and on the schedule.</Text> :
              <Text customCss={css`color: ${NEUTRAL_70};`}>We WILL show emojis next to your display name and other users' display names in sessions and on the schedule.</Text>
            }
          </Form.Group>
        </Form.Row>
        <Form.Row>
        {!hideDisplayNameEmojis &&
          <Form.Group as={Col} md="6">
          <Form.Label>{fieldDisplayMapping.emojis} <EmojiHelpIcon /></Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="emojis"
                ref={register}
                placeholder={`🌊🧠✨`}
                defaultValue={localEmojis}
                onChange={(e) => {
                  const value = checkForOnlyEmojisAndMaxLength(e)
                  setLocalEmojis(value)
                }}
              />
            </InputGroup>
          </Form.Group>}
          <Form.Group as={Col} md={hideDisplayNameEmojis ? 12 : 6}>
            <b>Preview:</b><br/>
            {localDisplayName}{hideDisplayNameEmojis ? '' : ` ${localEmojis}`}
            <Text customCss={css`color: ${NEUTRAL_70};`}>This is how your name will appear in sessions and on the schedule.</Text>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>{fieldDisplayMapping.pronunciation}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="pronunciation"
                ref={register}
                placeholder={`ME-HALL-E or Me + Wally but with an H`}
                defaultValue={user.pronunciation}
                onChange={checkAndRemoveEmojis}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>{fieldDisplayMapping.location}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="location"
                ref={register}
                defaultValue={user.location}
                placeholder="San Francisco, CA"
                onChange={checkAndRemoveEmojis}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>{fieldDisplayMapping.company}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="company"
                ref={register}
                defaultValue={user.company}
                placeholder="Acme"
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col md="12">
            <Form.Label>Degrees and Certifications</Form.Label>
          </Col>
          <Form.Group as={Col} md="12">
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>🔜📜 Currently In-Progress</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="inProgressCertifications"
                ref={register}
                defaultValue={user.inProgressCertifications}
                placeholder="MBA, MPH, Project Management Professional (PMP)"
                onChange={checkAndRemoveEmojis}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="12">
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>✅📜 Completed</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="completedCertifications"
                ref={register}
                defaultValue={user.completedCertifications}
                placeholder="ICF Credential (ACC, PCC, MCC)"
                onChange={checkAndRemoveEmojis}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col md="12">
            <Form.Label>Share your links with members</Form.Label>
          </Col>
          <Form.Group as={Col} md="6">
            <InputGroup className="mb-2">
            <Icon type={TYPE_LINK} css={css`margin-right: 4px; width: 36px; height: 36px;`}/>
              <Form.Control
                type="url"
                name="linkUrl"
                ref={register}
                placeholder={fieldDisplayMapping.linkUrl}
                defaultValue={user.linkUrl}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="linkTitle"
                ref={register}
                placeholder={fieldDisplayMapping.linkTitle}
                defaultValue={user.linkTitle}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <InputGroup className="mb-2">
              <Icon type={TYPE_LINKEDIN_SQUARE} css={css`margin-right: 4px; width: 36px; height: 36px;`} />
              <Form.Control
                type="url"
                name="linkedin"
                ref={register}
                placeholder={fieldDisplayMapping.linkedin}
                defaultValue={user.linkedin}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <InputGroup className="mb-2">
              <Icon type={TYPE_TWITTER_SQUARE} css={css`margin-right: 4px; width: 36px; height: 36px;`} />
              <Form.Control
                type="url"
                name="twitter"
                ref={register}
                defaultValue={user.twitter}
                placeholder={fieldDisplayMapping.twitter}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What activities put you most in the flow?</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                name="flowActivities"
                ref={register}
                defaultValue={user.flowActivities}
                placeholder="Writing, coding, playing guitar, running"
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Button variant="primary" size="lg" type="submit" block className="my-3" disabled={updatingUser}>
          {updatingUser ?
            <span>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Updating...
            </span>
            :
            <span>Update</span>
          }
        </Button>
      </Form>
    </Container>
  );
}

export default UpdateProfile;
